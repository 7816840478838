import {Fragment, useEffect} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Button from '@teladoc/pulse/ui/Button';
import Loader from '@teladoc/pulse/ui/Loader';
import Image from '@teladoc/pulse/ui/Image';

import {LIST_PROGRAM_CONFORMATION} from 'constants/programs';
import {fetchPersonalInfo, redirectToMemberPortal} from 'store/user';
import {fetchPrograms} from 'store/programs';
import messageIds from 'constants/messages';
import {ELIGIBLE_SHIPPING_CONFIRMATION_URL, ERROR_URL} from 'App';
import {
    programConfirmationLand,
    programsConfirmationPass,
} from 'utilities/mixpanel-utils';
import {getIsOneApp} from 'utilities/utils';

const {ERROR_BAD_REQUEST} = messageIds;

import css from './ProgramConfirmation.scss';

const ProgramConfirmation = () => {
    const {t} = useTranslation('content', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const {programsSuccessfullyEnrolled} = useSelector(state => state.programs);
    const {authToken, loading: isLoading} = useSelector(state => state.user);
    const isSingleProgram = programsSuccessfullyEnrolled.length === 1;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        programConfirmationLand();
        dispatch(fetchPersonalInfo());
        dispatch(redirectToMemberPortal(authToken));
        dispatch(fetchPrograms());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (programsSuccessfullyEnrolled.length === 0) {
        return (
            <Redirect
                to={{
                    pathname: ERROR_URL,
                    search: `?type=${ERROR_BAD_REQUEST}`,
                }}
            />
        );
    }

    const toShippingConfirmation = () => {
        programsConfirmationPass();
        history.push(ELIGIBLE_SHIPPING_CONFIRMATION_URL);
    };
    const multiProgramSection = (
        <section className={css.sectionContainerMultiPrograms}>
            <ul>
                {LIST_PROGRAM_CONFORMATION.map(value => {
                    return (
                        programsSuccessfullyEnrolled.includes(
                            value.program
                        ) && (
                            <li key={value.program}>
                                <Image
                                    className={css.image}
                                    cloudinaryImageId={
                                        value.imgUrl.multiProgram
                                    }
                                    width={424}
                                    height={250}
                                    alt={t(value.title)}
                                />
                                <div className={css.content}>
                                    <h2>{t(value.title)}</h2>
                                    <p>{t(value.description)}</p>
                                </div>
                            </li>
                        )
                    );
                })}
            </ul>
            <Button color="primary" block onClick={toShippingConfirmation}>
                {t('buttons.looksGood')}
            </Button>
        </section>
    );
    const singleProgramSection = (
        <section
            className={cn(css.sectionContainerSingleProgram, {
                [css.oneAppImgContainer]: isOneApp,
            })}
        >
            {LIST_PROGRAM_CONFORMATION.map(value => {
                return (
                    programsSuccessfullyEnrolled.includes(value.program) && (
                        <Fragment key={value.program}>
                            {!isOneApp && (
                                <>
                                    <Image
                                        className={css.image}
                                        cloudinaryImageId={
                                            value.imgUrl.singleProgram
                                        }
                                        width={500}
                                        height={170}
                                        alt={t(value.title)}
                                    />
                                    <div className={css.content}>
                                        <h2>{t(value.title)}</h2>
                                        <p>{t(value.description)}</p>
                                    </div>
                                </>
                            )}
                            {isOneApp && (
                                <>
                                    <Image
                                        className={css.image}
                                        cloudinaryImageId={value.oneAppImgUrl}
                                        width={488}
                                        height={150}
                                        alt={t(value.title)}
                                    />
                                    <div className={css.oneAppContent}>
                                        <h2>{t(value.oneAppTitle)}</h2>
                                        <p>{t(value.description)}</p>
                                    </div>
                                </>
                            )}
                        </Fragment>
                    )
                );
            })}

            {!isOneApp && (
                <Button
                    variant="primary"
                    block
                    className={css.buttonNext}
                    onClick={toShippingConfirmation}
                >
                    {t('buttons.looksGood')}
                </Button>
            )}
            {isOneApp && (
                <Button
                    variant="primary"
                    className={css.oneAppNextButton}
                    block
                    onClick={toShippingConfirmation}
                >
                    {t('buttons.next')}
                </Button>
            )}
        </section>
    );

    // TODO: this is reading programsSuccessfullyEnrolled from the program store,
    // we need to handle the case the use reloads the page and tha is empty

    return isLoading ? (
        <Loader className="loader" />
    ) : (
        <div
            className={cn(css.root, {
                [css.oneAppRoot]: isOneApp,
            })}
        >
            <div className={css.container}>
                <div
                    className={cn(css.mainContainer, {
                        [css.oneAppMainContainer]: isOneApp,
                    })}
                >
                    {!isOneApp && (
                        <section className={css.sectionContainer}>
                            <h1>
                                {t('Confirmation.programConfirmation.title')}
                            </h1>
                            <p>
                                {t(
                                    'Confirmation.programConfirmation.description'
                                )}
                            </p>
                        </section>
                    )}
                    {isOneApp && (
                        <section className={css.oneAppSectionContainer}>
                            <h1>
                                {t(
                                    'Confirmation.programConfirmation.oneAppTitle'
                                )}
                            </h1>
                        </section>
                    )}
                    {isSingleProgram
                        ? singleProgramSection
                        : multiProgramSection}
                </div>
            </div>
        </div>
    );
};

export default ProgramConfirmation;
