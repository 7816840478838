import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import TextInput from '@teladoc/pulse/ui/TextInput';
import {questionConfig} from 'constants/questions';
import {renderLabel} from './../questions-utils';
import css from '../Question.scss';

const Weight = ({
    id,
    name,
    required,
    defaultValue,
    defaultDisabled = false,
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    // Get question UI configuration.
    const {label, ariaLabel, autocomplete, placeholder, error} =
        questionConfig[id] || {};
    const textInputId = `txt_${id}`;
    const unit = 'lbs';
    // This is the display value with the unit shown
    const [weightValue, setWeightValue] = useState(defaultValue || '');
    const [
        disableWeightInput,
        // setDisableWeightInput
    ] = useState(defaultDisabled);
    // This is the float value that will be passed when a form submit happens
    const [weightValueHiddenValue, setWeightValueHiddenValue] = useState(
        defaultValue ? `${defaultValue} ${unit}` : ''
    );
    const [placeHolder, setPlaceHolder] = useState(t(placeholder));
    /*
    const onWeightChange = evt => {
        setDisableWeightInput(evt.target.checked);
        setWeightValueHiddenValue(evt.target.checked ? IDK : weightValue);
        if (onChange) {
            onChange(evt.target.checked ? IDK : weightValue);
        }
    };
    */
    const handleTextOnChange = evt => {
        const {value} = evt.target;
        let newValue = value.replace(/[^0-9]/g, '').replace(/^0+/g, '');

        setWeightValueHiddenValue(newValue);
        if (onChange) {
            onChange(newValue);
        }

        // add a lbs unit
        if (newValue.length !== 0) {
            newValue = `${newValue} ${unit}`;
        }
        setWeightValue(newValue);

        // This is for setting the carat
        // put this in front of the unit
        // setTimeout is done after the render
        setTimeout(() => {
            const elem = document.getElementById(textInputId);

            if (elem !== null && elem.selectionStart && newValue.length > 0) {
                const position = newValue.length - (unit.length + 1); // in front of the unit

                elem.focus();
                elem.setSelectionRange(position, position);
            }
        }, 0);
    };
    const onTextInputFocus = evt => {
        setPlaceHolder('0 lbs');
    };
    const onTextInputBlur = evt => {
        setPlaceHolder(t(placeholder));
    };

    return (
        <div className={css.root}>
            <TextInput
                {...renderLabel(t(label), t(ariaLabel))}
                id={textInputId}
                placeholder={t(placeHolder)}
                autoComplete={autocomplete}
                min={0}
                max={1}
                step={0.1}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleTextOnChange}
                onFocus={onTextInputFocus}
                onBlur={onTextInputBlur}
                disabled={disableWeightInput}
                required={required || !disableWeightInput}
                value={weightValue}
            />
            <TextInput
                // this hidden text input is only for submit form
                id={id}
                name={name}
                type="hidden"
                i18nItemLabel="hidden"
                value={weightValueHiddenValue}
            />
        </div>
    );
};

Weight.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    defaultDisabled: PropTypes.bool,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} weightValue - Either the Weight Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default Weight;
