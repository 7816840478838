export const SECTION_TYPES = {
    QUESTION: 'QUESTION',
    INFORMATION: 'INFORMATION',
};

export const questionUiType = {
    TEXT_INPUT: 'textInput',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    MULTI_SELECT: 'multiSelect',
    COMPOUND: 'compound',
    PASSWORD: 'password',
    ADDRESS: 'address',
    A1C_DATE: 'a1cDate',
    A1C_VALUE: 'a1cValue',
    REG_CODE: 'regCode',
    BIRTH_DATE: 'birthDate',
    SMS_OPT_IN: 'sms',
    TERMS_AND_CONDITIONS: 'termsAndConditions',
    PROGRAM: 'program',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    PHONE_INPUT: 'phone',
    LIVONGO_CONTACT: 'livongo_contact',
    ZIP: 'zip',
    STATE: 'state',
};

export const errorTypes = {
    WRONG_TYPE: 'wrongType',
    MISSING: 'missing',
    UNDERFLOW: 'underflow',
    OVERFLOW: 'overflow',
    ILLEGAL_ENUM_VALUE: 'illegalEnumValue',
    ILLEGAL_SCALAR_VALUE: 'illegalScalarValue',
    PASSWORD_MISSING_CHAR_SETS: 'passwordMissingCharSets',
    DATE_DOES_NOT_EXIST: 'dateDoesNotExist',
    INVALID_PROGRAM_SET: 'invalidProgramsSet',
    EMAIL_IN_USE: 'emailInUse',
    ANSWERED_TWICE: 'answeredTwice',
    FLOW_ERROR: 'flowError',
    DUPLICATES: 'duplicates',
    UNDEFINED: 'undefined',
    UNKNOWN_QUESTION_ID: 'unknownQuestionId',
    UNKNOWN_ENUM_VALUE: 'unknownEnumValue',
    UNKNOWN_NODE: 'unknownNode',
    UNKNOWN_BRANCH_POINT: 'unknownBranchPoint',
    UNKNOWN_REFERENCE: 'unknownReference',
};

export const IDK = 'I_DONT_KNOW_skdl';
