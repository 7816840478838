import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {questionConfig} from 'constants/questions';
import Select from '@teladoc/pulse/ui/Select';
import TextInput from '@teladoc/pulse/ui/TextInput';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import useAllMonths from 'hook/useAllMonths';
import css from './BirthDate.scss';

const CURRENT_YEAR = String(new Date().getFullYear());
const CURRENT_MONTH = String(new Date().getMonth()) + 1;
const CURRENT_DATE = Number(new Date().getDate());
const currentYear = new Date().getUTCFullYear();
let yearRange = [];
let i = currentYear - 120;

for (i; i <= currentYear; i++) {
    yearRange.push({
        label: String(i),
        value: String(i),
    });
}
yearRange = yearRange.reverse();

const pick31Days = [];

for (i = 1; i <= 31; i++) {
    pick31Days.push({
        label: String(i),
        value: String(i),
    });
}

const pick30Days = [];

for (i = 1; i <= 30; i++) {
    pick30Days.push({
        label: String(i),
        value: String(i),
    });
}
const pick29Days = [];

for (i = 1; i <= 29; i++) {
    pick29Days.push({
        label: String(i),
        value: String(i),
    });
}

const pick28Days = [];

for (i = 1; i <= 28; i++) {
    pick28Days.push({
        label: String(i),
        value: String(i),
    });
}

const pickCurrentDays = [];

pickCurrentDays.push({label: '', value: null});
for (i = 1; i <= CURRENT_DATE; i++) {
    pickCurrentDays.push({
        label: String(i),
        value: String(i),
    });
}

const BirthDate = ({
    id,
    name,
    required,
    defaultYear,
    defaultMonth,
    defaultDay,
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    const [allMonths, currentYearMonths] = useAllMonths();
    const {
        yearAriaLabel,
        monthAriaLabel,
        dayAriaLabel,
        yearPlaceholder,
        monthPlaceholder,
        dayPlaceholder,
        error,
    } = questionConfig[id] || {};
    const [year, setYear] = useState(defaultYear ? `${defaultYear}` : null);
    const [month, setMonth] = useState(defaultMonth ? `${defaultMonth}` : null);
    const [day, setDay] = useState(defaultDay ? `${defaultDay}` : null);
    const [isLeapYear, setIsLeapYear] = useState(false);
    const [monthItem, setMonthItem] = useState(pick31Days);
    const [dateOfBirth, setDateOfBirth] = useState(
        `${defaultYear}-${defaultMonth}-${defaultDay}`
    );
    const onDayChange = evt => {
        if (!evt) {
            return;
        }
        const {value} = evt;

        if (value !== day) {
            setDay(value);
        }

        if (onChange) {
            onChange(`${year}-${month}-${value}`);
        }
    };
    const onMonthChange = ({value}) => {
        if (value !== month) {
            setMonth(value);
        }

        if (onChange) {
            onChange(`${year}-${value}-${day}`);
        }
    };
    const onYearChange = ({value}) => {
        if (value !== year) {
            setYear(value);
        }

        if (onChange) {
            onChange(`${value}-${month}-${day}`);
        }
    };

    useEffect(() => {
        if (year && year % 100 === 0 && year % 400 === 0) {
            setIsLeapYear(true);
        } else if (year && year % 100 !== 0 && year % 4 === 0) {
            setIsLeapYear(true);
        } else {
            setIsLeapYear(false);
        }
        if (
            month === '1' ||
            month === '3' ||
            month === '5' ||
            month === '7' ||
            month === '8' ||
            month === '10' ||
            month === '12'
        ) {
            setMonthItem(pick31Days);
        } else if (
            month === '4' ||
            month === '6' ||
            month === '9' ||
            month === '11'
        ) {
            setMonthItem(pick30Days);
        } else if (month === '2') {
            if (isLeapYear) {
                setMonthItem(pick29Days);
            } else {
                setMonthItem(pick28Days);
            }
        }
        if (year && month && day) {
            setDateOfBirth(`${year}-${month}-${day}`);
        }
    }, [year, month, day, isLeapYear, dateOfBirth]);

    return (
        <div className={css.root}>
            <Select
                id={`month_${id}`}
                defaultValue={month}
                classNameRoot={cn(css.selectMonth)}
                i18nItemLabel={t(monthAriaLabel)}
                items={year === CURRENT_YEAR ? currentYearMonths : allMonths}
                placeholder={t(monthPlaceholder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onMonthChange}
                required={required}
            />
            <Select
                id={`day_${id}`}
                defaultValue={day}
                classNameRoot={cn(css.selectDay)}
                i18nItemLabel={t(dayAriaLabel)}
                items={
                    year === CURRENT_YEAR && month === CURRENT_MONTH
                        ? pickCurrentDays
                        : monthItem
                }
                placeholder={t(dayPlaceholder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onDayChange}
                required={required}
            />
            <Select
                id={`year_${id}`}
                defaultValue={year}
                classNameRoot={cn(css.selectYear)}
                i18nItemLabel={t(yearAriaLabel)}
                items={yearRange}
                placeholder={t(yearPlaceholder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onYearChange}
                required={required}
            />
            <TextInput
                id={id}
                name={name}
                type="hidden"
                // TODO: Update to a more proper label.
                i18nItemLabel="hidden"
                value={dateOfBirth}
            />
        </div>
    );
};

BirthDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number,
    defaultMonth: PropTypes.number,
    defaultDay: PropTypes.number,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default BirthDate;
