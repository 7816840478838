import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Config from 'config';
import {messageValues} from 'constants/messages';

import css from './modalStyle.scss';

const {memberSupportPhone, memberSupportPhoneFormatted} = Config;

const MemberSupportNumber = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const messageValue = messageValues[id] || {};
    const {title, content} = messageValue;

    return (
        <div className={css.modalContainer}>
            {title && <h1 className={css.a2}>{t(title)}</h1>}

            {content && Array.isArray(content) ? (
                content.map((contentLine, i) => (
                    <div key={i} className={css.contentContainer}>
                        <p>{t(contentLine)}</p>
                    </div>
                ))
            ) : (
                <div className={css.contentContainer}>
                    <p>{t(content)}</p>
                </div>
            )}
            <div>
                <div className={css.memberSupportText}>
                    {t('common.memberSupport')}
                </div>
                <a
                    className={css.memberSupportNumber}
                    href={`tel:${memberSupportPhone}`}
                >
                    {memberSupportPhoneFormatted}
                </a>
            </div>
        </div>
    );
};

MemberSupportNumber.propTypes = {
    id: PropTypes.string.isRequired,
};

export default MemberSupportNumber;
