export const BUILD_KEY = process.env.BUILD_KEY;
export const BUILD_ID = process.env.BUILD_ID;
// eslint-disable-next-line node/no-extraneous-import
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';
// eslint-disable-next-line node/no-extraneous-import
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

export default function init({lang}) {
    const appNamespace = 'app';
    const commonNamespace = 'common';
    const contentNamespace = 'content';
    const messagesNamespace = 'messages';
    const programsNamespace = 'programs';
    const questionsNamespace = 'questions';
    const backends = [XHR];

    return i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            backend: {
                backends,
            },
            ns: [
                appNamespace,
                commonNamespace,
                contentNamespace,
                messagesNamespace,
                programsNamespace,
                questionsNamespace,
            ],
            defaultNS: appNamespace,
            fallbackNS: commonNamespace,
            lng: lang,
            interpolation: {
                escapeValue: false,
            },
        });
}

const languageCodes = {
    default: 'en-US',
    'en-us': 'en-US',
    'es-us': 'es-US',
};

export const getLanguageCode = lang => {
    let languageCode;

    if (lang) {
        languageCode = languageCodes[lang.toLowerCase()];
    }

    if (!languageCode) {
        languageCode = languageCodes.default;
    }

    return languageCode;
};
