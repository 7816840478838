import PropTypes from 'prop-types';
import {useTranslation, Trans} from 'react-i18next';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Label from '@teladoc/pulse/ui/Label';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import ScreenReader from 'common/screen-reader/ScreenReader';
import {questionConfig} from 'constants/questions';
import Config from 'config';
const {tosUrl, privacyUrl, ndnUrl, mcpUrl} = Config;

const TermsAndConditions = ({id, name, required}) => {
    const {title, error} = questionConfig[id] || {};
    const {t} = useTranslation('questions');
    /* eslint-disable no-empty-function */
    const onTermsOfServiceClick = () => {};
    const onMemberCommunicationPolicyClick = () => {};
    const onNonDiscriminationDisclosureClick = () => {};
    const onPrivacyPracticesClick = () => {};
    /* eslint-enable no-empty-function */

    return (
        <Checkbox
            id={id}
            name={name}
            value="true"
            label={
                <Label>
                    <Trans
                        i18nKey="questions:TERMS_AND_CONDITIONS.label"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a
                                href={tosUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onTermsOfServiceClick}
                            >
                                <ScreenReader />
                            </a>,
                            <a
                                href={privacyUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onPrivacyPracticesClick}
                            >
                                <ScreenReader />
                            </a>,
                            <a
                                href={ndnUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onNonDiscriminationDisclosureClick}
                            >
                                <ScreenReader />
                            </a>,
                            <a
                                href={mcpUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={onMemberCommunicationPolicyClick}
                            >
                                <ScreenReader />
                            </a>,
                            /* eslint-enable react/jsx-key */
                        ]}
                    />
                </Label>
            }
            title={t(title)}
            error={<FormElementError>{t(error)}</FormElementError>}
            required={required}
        />
    );
};

TermsAndConditions.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
};

export default TermsAndConditions;
