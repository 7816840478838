import merge from 'lodash/merge';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import {SectionId} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/section_ids_pb';
import {SECTION_TYPES} from 'constants/type';

const locale = getStoredLang().toLowerCase();

const {
    Values: {SIGN_UP, COVERAGE, ABOUT_YOU, WELCOME},
} = SectionId;

const Config = {
    vNum: '2.0.0',
    myRefreshCookie: 'my_refreshToken',
    refreshCookie: 'mp-refresh-token',
    langCookie: 'mp-lang',
    memberSupportEmail: 'membersupport@livongo.com',
    oneAppMemberSupportEmail: 'membersupport@teladochealth.com',
    memberSupportUrl: 'https://www.livongo.com', // TODO: Define this URL when we have it
    zendeskUrl: `https://livongo.zendesk.com/hc/${locale}`,
    healthyEating: `https://goto.livongo.com/he?locale=${locale}`,
    smsIframe: 'https://livongoapp.app.link/install',
    memberSupportPhone: '8009454355',
    memberSupportPhoneFormatted: '(800) 945-4355',
    oneAppMemberSupportPhoneFormatted: '(800) 835-2362',
    helpSupport: 'help@livongo.com',
    textStop: '85240',
    tosUrl: `https://goto.livongo.com/terms-of-service?locale=${locale}`,
    privacyUrl: `https://goto.livongo.com/web-privacy-practice?locale=${locale}`,
    ndnUrl: `https://goto.livongo.com/notice-language-assistance?locale=${locale}`,
    mcpUrl: `https://goto.livongo.com/member-communication-policy?locale=${locale}`,
    nameInputPattern: '[-0-9A-zÀ-ž ]+',
    addressInputPattern: '[-.0-9A-zÀ-ž #]+',
    // Todo: Move values apiUrl, apiFEUrl , and memberPortalUrl to a file for environment variables
    apiUrl: 'https://mw.integration.livongo.com/v1/clients/search/',
    apiFEUrl: 'http://localhost:3333/section/',
    memberPortalUrl: 'https://my.integration.livongo.com',
    registrationFlowUrl: 'http://welcome2.integration.livongo.com/registration',
    myStrengthDefaultURL: 'https://web-ui.mystrength.livongo.com/login',
    idleTimerDebounce: 1000,
    idleTimerTimeout: 900000,
    userUUID: 'registration_user_uuid',
    userSessionExpires: 'registration_user_session_expires',
    grpcAccessToken: 'registration_grpc_access_token',
    stepUrls: 'registration_stepUrls',
    stepIndex: 'registration_stepIndex',
    stepLoader: 'registration_stepLoader',
    clientFriendlyName: 'registration_client_friendly_name',
    registerStartUrl: '/registration',
    myStrengthURLPREPROD: 'https://web-ui-stage.mystrength.livongo.com/login',
    myStrengthURLINT: 'https://web-ui-bhdts.mystrength.livongo.com/login',
    myStrengthAppStoreLink: 'https://mystrength.app.link',
    isOneApp: 'registration_isOneApp',
    customProgram: 'registration_program',
    returnUrl: 'registration_return_url',

    set(opts) {
        merge(this, opts);
    },

    /*
     * Here will be more sections variant defined for different experience.
     * Type of questions section id will be integer since they are defined as enum in gRPC proto.
     * Type of information section id will be string.
     */
    sectionsVwo: [
        {
            id: SIGN_UP,
            title: 'signUp',
            pathname: 'sign-up',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        // {
        //     id: 'HELLO',
        //     title: 'Hello',
        //     pathname: 'hello',
        //     layout: 'WideNarrow',
        //     type: SECTION_TYPES.INFORMATION,
        // },
        {
            id: COVERAGE,
            title: 'coverage',
            pathname: 'coverage',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: ABOUT_YOU,
            title: 'aboutYou',
            pathname: 'about-you',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: WELCOME,
            title: 'welcome',
            pathname: 'welcome',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],

    sectionsDefault: [
        {
            id: SIGN_UP,
            title: 'signUp',
            pathname: 'sign-up',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        // {
        //     id: 'HELLO',
        //     title: 'Hello',
        //     pathname: 'hello',
        //     layout: 'WideNarrow',
        //     type: SECTION_TYPES.INFORMATION,
        // },
        {
            id: COVERAGE,
            title: 'coverage',
            pathname: 'coverage',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: ABOUT_YOU,
            title: 'aboutYou',
            pathname: 'about-you',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
        {
            id: WELCOME,
            title: 'welcome',
            pathname: 'welcome',
            layout: 'WideNarrow',
            type: SECTION_TYPES.QUESTION,
        },
    ],
};

export default Config;
