export const questionFieldTypes = {
    TEXT_FIELD: 'textField',
    SINGLE_SELECT_ENUM: 'singleSelectEnum',
    MULTI_SELECT_ENUM: 'multiSelectEnum',
    BOOLEAN_FIELD: 'boolField',
    INTEGER_FIELD: 'intField',
    FLOAT_FIELD: 'floatField',
    DATE_FIELD: 'dateField',
    COMPOUND_FIELD: 'compoundField',
};

// TODO: Add autocomplete for all other questions?
export const questionConfig = {
    UNKNOWN: {
        id: 'unknown',
        uiType: 'unknown',
    },
    EVER_HAD_A1C: {
        id: 'everHadA1c',
        title: 'EVER_HAD_A1C.title',
        label: 'EVER_HAD_A1C.label',
        ariaLabel: 'EVER_HAD_A1C.ariaLabel',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'EVER_HAD_A1C.info.emoji.character',
                ariaLabel: 'EVER_HAD_A1C.info.emoji.ariaLabel',
            },
            title: 'EVER_HAD_A1C.info.title',
            content: 'EVER_HAD_A1C.info.content',
        },
    },
    A1C_LAST_CHECKED_VALUE: {
        id: 'a1cLastCheckedValue',
        uiType: 'a1cValue',
        type: 'number',
        pattern: '[-0-9A-zÀ-ž ]+',
        title: 'A1C_LAST_CHECKED_VALUE.title',
        ariaLabel: 'A1C_LAST_CHECKED_VALUE.ariaLabel',
        checkboxLabel: 'A1C_LAST_CHECKED_VALUE.checkboxLabel',
        placeholder: 'A1C_LAST_CHECKED_VALUE.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'A1C_LAST_CHECKED_VALUE.info.emoji.character',
                ariaLabel: 'A1C_LAST_CHECKED_VALUE.info.emoji.ariaLabel',
            },
            title: 'A1C_LAST_CHECKED_VALUE.info.title',
            content: 'A1C_LAST_CHECKED_VALUE.info.content',
        },
    },
    A1C_LAST_CHECKED_DATE: {
        id: 'a1cLastCheckedDate',
        uiType: 'a1cDate',
        title: 'A1C_LAST_CHECKED_DATE.title',
        label: 'A1C_LAST_CHECKED_DATE.label',
        ariaLabel: 'A1C_LAST_CHECKED_DATE.ariaLabel',
        checkboxLabel: 'A1C_LAST_CHECKED_DATE.checkboxLabel',
        yearAriaLabel: 'A1C_LAST_CHECKED_DATE.yearAriaLabel',
        monthAriaLabel: 'A1C_LAST_CHECKED_DATE.monthAriaLabel',
        yearPlaceholder: 'A1C_LAST_CHECKED_DATE.yearPlaceholder',
        monthPlaceholder: 'A1C_LAST_CHECKED_DATE.monthPlaceholder',
        error: 'common:error.required',
    },
    ADDRESS_LINE1: {
        id: 'addressLine1',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        autocomplete: 'address-line1',
        title: 'ADDRESS_LINE1.title',
        label: 'ADDRESS_LINE1.label',
        placeholder: 'ADDRESS_LINE1.placeholder',
        error: 'ADDRESS_LINE1.error-line1-empty',
        errorInvalidCharacter: 'ADDRESS_LINE1.error-long-characters',
        ariaLabel: 'ADDRESS_LINE1.ariaLabel',
    },
    ADDRESS_LINE2: {
        id: 'addressLine2',
        type: 'text',
        pattern: '[-.0-9A-zÀ-ž #]+',
        autocomplete: 'address-line2',
        title: 'ADDRESS_LINE2.title',
        label: 'ADDRESS_LINE2.label',
        placeholder: 'ADDRESS_LINE2.placeholder',
        optionalText: 'ADDRESS_LINE2.optionalText',
        errorInvalidCharacter: 'ADDRESS_LINE2.error-long-characters',
        ariaLabel: 'ADDRESS_LINE2.ariaLabel',
    },
    BIRTH_DATE: {
        id: 'birthDate',
        uiType: 'birthDate',
        autocompleteDay: 'bday-day',
        autocompleteMonth: 'bday-month',
        autocompleteYear: 'bday-year',
        title: 'BIRTH_DATE.title',
        label: 'BIRTH_DATE.label',
        ariaLabel: 'BIRTH_DATE.ariaLabel',
        yearAriaLabel: 'BIRTH_DATE.yearAriaLabel',
        monthAriaLabel: 'BIRTH_DATE.monthAriaLabel',
        dayAriaLabel: 'BIRTH_DATE.dayAriaLabel',
        yearPlaceholder: 'BIRTH_DATE.yearPlaceholder',
        monthPlaceholder: 'BIRTH_DATE.monthPlaceholder',
        dayPlaceholder: 'BIRTH_DATE.dayPlaceholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'BIRTH_DATE.info.emoji.character',
                ariaLabel: 'BIRTH_DATE.info.emoji.ariaLabel',
            },
            title: 'BIRTH_DATE.info.title',
            content: 'BIRTH_DATE.info.content',
        },
    },
    BLOOD_PRESSURE_RANGE: {
        id: 'bloodPressureRange',
        title: 'BLOOD_PRESSURE_RANGE.title',
        label: 'BLOOD_PRESSURE_RANGE.label',
        placeholder: 'BLOOD_PRESSURE_RANGE.placeholder',
        error: 'common:error.required',
    },
    BP_CHECKS_SELF_REPORTED: {
        id: 'bpChecksSelfReported',
        title: 'BP_CHECKS_SELF_REPORTED.title',
        label: 'BP_CHECKS_SELF_REPORTED.label',
        placeholder: 'BP_CHECKS_SELF_REPORTED.placeholder',
        error: 'common:error.required',
    },
    CGM: {
        id: 'cgm',
        title: 'CGM.title',
        label: 'CGM.label',
        placeholder: 'CGM.placeholder',
        error: 'common:error.required',
    },
    CITY: {
        id: 'city',
        type: 'text',
        pattern: '[-0-9A-zÀ-ž ]+',
        autocomplete: 'address-level2',
        title: 'CITY.title',
        label: 'CITY.label',
        placeholder: 'CITY.placeholder',
        ariaLabel: 'CITY.ariaLabel',
        error: 'CITY.error',
        errorInvalidCharacter: 'CITY.error-long-characters',
    },
    COUNTRY: {
        id: 'country',
        autocomplete: 'country',
        title: 'COUNTRY.title',
        label: 'COUNTRY.label',
        placeholder: 'COUNTRY.placeholder',
        error: 'common:error.required',
    },
    CURRENT_ACTIVITY_LEVEL: {
        id: 'currentActivityLevel',
        title: 'CURRENT_ACTIVITY_LEVEL.title',
        label: 'CURRENT_ACTIVITY_LEVEL.label',
        placeholder: 'CURRENT_ACTIVITY_LEVEL.placeholder',
        error: 'common:error.required',
    },
    DAILY_BGCHECKS: {
        id: 'dailyBgchecks',
        type: 'text',
        title: 'DAILY_BGCHECKS.title',
        label: 'DAILY_BGCHECKS.label',
        placeholder: 'DAILY_BGCHECKS.placeholder',
        error: 'common:error.required',
    },
    DAILY_BGCHECKS_SELF_REPORTED: {
        id: 'dailyBgchecksSelfReported',
        type: 'text',
        title: 'DAILY_BGCHECKS_SELF_REPORTED.title',
        label: 'DAILY_BGCHECKS_SELF_REPORTED.label',
        placeholder: 'DAILY_BGCHECKS_SELF_REPORTED.placeholder',
        error: 'common:error.required',
    },
    DIABETES_DIAGNOSIS_DATE: {
        id: 'diabetesDiagnosisDate',
        uiType: 'birthDate',
        title: 'DIABETES_DIAGNOSIS_DATE.title',
        label: 'DIABETES_DIAGNOSIS_DATE.label',
        placeholder: 'DIABETES_DIAGNOSIS_DATE.placeholder',
        error: 'common:error.required',
    },
    DIABETES_MANAGEMENT: {
        id: 'diabetesManagement',
        title: 'DIABETES_MANAGEMENT.title',
        label: 'DIABETES_MANAGEMENT.label',
        placeholder: 'DIABETES_MANAGEMENT.placeholder',
        error: 'common:error.required',
    },
    DIABETES_TYPE: {
        id: 'diabetesType',
        title: 'DIABETES_TYPE.title',
        label: 'DIABETES_TYPE.label',
        ariaLabel: 'DIABETES_TYPE.ariaLabel',
        error: 'DIABETES_TYPE.error',
        options: [
            {value: 'T1', label: 'DIABETES_TYPE.options.Type1'},
            {value: 'T2', label: 'DIABETES_TYPE.options.Type2'},
        ],
    },
    EATING_HABITS_PAST_WEEK: {
        id: 'eatingHabitsPastWeek',
        type: 'text',
        title: 'EATING_HABITS_PAST_WEEK.title',
        label: 'EATING_HABITS_PAST_WEEK.label',
        placeholder: 'EATING_HABITS_PAST_WEEK.placeholder',
        error: 'common:error.required',
    },
    EMAIL: {
        id: 'email',
        type: 'text',
        pattern:
            '^(([a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+(.[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+)*)|(".+"))@(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,})$',
        autocomplete: 'email',
        title: 'EMAIL.title',
        label: 'EMAIL.label',
        placeholder: 'EMAIL.placeholder',
        error: 'EMAIL.error-email-format',
        info: {
            emoji: {
                character: 'EMAIL.info.emoji.character',
                ariaLabel: 'EMAIL.info.emoji.ariaLabel',
            },
            title: 'EMAIL.info.title',
            content: 'EMAIL.info.content',
        },
    },
    ENROLLMENT_MOTIVATION: {
        uiType: 'radio',
        title: 'ENROLLMENT_MOTIVATION.title',
        oneAppTitle: 'ENROLLMENT_MOTIVATION.oneAppTitle',
        ariaLabel: 'ENROLLMENT_MOTIVATION.ariaLabel',
        options: [
            {
                value: 'HEALTH_CARE_REC',
                label: 'ENROLLMENT_MOTIVATION.options.HealthCare',
            },
            {
                value: 'BLOOD_TEST',
                label: 'ENROLLMENT_MOTIVATION.options.BloodTest',
            },
            {
                value: 'RISK_SURVEY',
                label: 'ENROLLMENT_MOTIVATION.options.RiskSurvey',
            },
            {
                value: 'COMMUNITY',
                label: 'ENROLLMENT_MOTIVATION.options.Community',
            },
            {
                value: 'FAMILY_OR_FRIEND',
                label: 'ENROLLMENT_MOTIVATION.options.FamilyOrFriends',
            },
            {
                value: 'DPP_REFERRER',
                label: 'ENROLLMENT_MOTIVATION.options.DPPReferrer',
            },
            {
                value: 'EMPLOYER_BENEFIT',
                label: 'ENROLLMENT_MOTIVATION.options.EmploymentBenefit',
            },
            {
                value: 'HEALTH_INSURANCE',
                label: 'ENROLLMENT_MOTIVATION.options.HealthInsurance',
            },
            {
                value: 'ADVERTISEMENT',
                label: 'ENROLLMENT_MOTIVATION.options.Advertisements',
            },
        ],
        error: 'ENROLLMENT_MOTIVATION.error',
    },
    ENROLLMENT_SOURCE: {
        uiType: 'radio',
        title: 'ENROLLMENT_SOURCE.title',
        oneAppTitle: 'ENROLLMENT_SOURCE.oneAppTitle',
        ariaLabel: 'ENROLLMENT_SOURCE.ariaLabel',
        options: [
            {value: 'DOCTOR', label: 'ENROLLMENT_SOURCE.options.Doctor'},
            {
                value: 'PHARMACIST',
                label: 'ENROLLMENT_SOURCE.options.Pharmacist',
            },
            {value: 'CARE_TEAM', label: 'ENROLLMENT_SOURCE.options.CareTeam'},
            {value: 'NO', label: 'ENROLLMENT_SOURCE.options.None'},
        ],
        error: 'ENROLLMENT_SOURCE.error',
    },
    FIRST_NAME: {
        id: 'firstName',
        type: 'text',
        pattern: '[-0-9A-zÀ-ž ]+',
        autocomplete: 'first-name',
        title: 'FIRST_NAME.title',
        label: 'FIRST_NAME.label',
        placeholder: 'FIRST_NAME.placeholder',
        error: 'FIRST_NAME.error-firstname-format',
        info: {
            emoji: {
                character: 'FIRST_NAME.info.emoji.character',
                ariaLabel: 'FIRST_NAME.info.emoji.ariaLabel',
            },
            title: 'FIRST_NAME.info.title',
            content: 'FIRST_NAME.info.content',
        },
    },
    FLU_VACCINE: {
        id: 'fluVaccine',
        title: 'FLU_VACCINE.title',
        label: 'FLU_VACCINE.label',
        placeholder: 'FLU_VACCINE.placeholder',
        error: 'common:error.required',
    },
    GENDER: {
        id: 'gender',
        title: 'GENDER.title',
        label: 'GENDER.label',
        ariaLabel: 'GENDER.ariaLabel',
        error: 'GENDER.error',
        oneAppTitle: 'GENDER.oneAppTitle',
        oneAppLabel: 'GENDER.oneAppLabel',
        info: {
            emoji: {
                character: 'GENDER.info.emoji.character',
                ariaLabel: 'GENDER.info.emoji.ariaLabel',
            },
            title: 'GENDER.info.title',
            content: 'GENDER.info.content',
        },
    },
    GENDER_IDENTITY: {
        uiType: 'radio',
        title: 'GENDER_IDENTITY.title',
        oneAppTitle: 'GENDER_IDENTITY.oneAppTitle',
        oneAppLabel: 'GENDER_IDENTITY.oneAppLabel',
        ariaLabel: 'GENDER_IDENTITY.ariaLabel',
        options: [
            {value: 'MALE', label: 'GENDER_IDENTITY.options.Male'},
            {value: 'FEMALE', label: 'GENDER_IDENTITY.options.Female'},
            {value: 'NON_BINARY', label: 'GENDER_IDENTITY.options.NonBinary'},
            {
                value: 'TRANSGENDER',
                label: 'GENDER_IDENTITY.options.Transgender',
            },
            {
                value: 'PREFER_NOT_TO_SAY',
                label: 'GENDER_IDENTITY.options.PreferNotToSay',
            },
            {
                value: 'PREFER_SELF_IDENTIFY',
                label: 'GENDER_IDENTITY.options.PreferSelfIdentify',
            },
        ],
        error: 'GENDER_IDENTITY.error',
    },
    HEIGHT: {
        id: 'height',
        uiType: 'height',
        title: 'HEIGHT.title',
        oneAppTitle: 'HEIGHT.oneAppTitle',
        label: 'HEIGHT.label',
        ariaLabel: 'HEIGHT.ariaLabel',
        selectFeetAriaLabel: 'HEIGHT.selectFeetAriaLabel',
        selectInchesAriaLabel: 'HEIGHT.selectInchesAriaLabel',
        feetPlaceholder: 'HEIGHT.feetPlaceholder',
        inchesPlaceholder: 'HEIGHT.inchesPlaceholder',
        error: 'HEIGHT.error-height-empty',
        errorHeightFeet: 'HEIGHT.error-feet-empty',
        errorHeightInches: 'HEIGHT.error-inches-empty',
    },
    HYPERTENSION_DIAGNOSIS_DATE: {
        id: 'hypertensionDiagnosisDate',
        type: 'text',
        title: 'HYPERTENSION_DIAGNOSIS_DATE.title',
        label: 'HYPERTENSION_DIAGNOSIS_DATE.label',
        placeholder: 'HYPERTENSION_DIAGNOSIS_DATE.placeholder',
        error: 'common:error.required',
    },
    HYPERTENSION_MEDS: {
        id: 'hypertensionMeds',
        title: 'HYPERTENSION_MEDS.title',
        label: 'HYPERTENSION_MEDS.label',
        error: 'common:error.required',
    },
    DIABETES_MEDS: {
        id: 'diabetesMeds',
        title: 'DIABETES_MEDS.title',
        label: 'DIABETES_MEDS.label',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'DIABETES_MEDS.info.emoji.character',
                ariaLabel: 'DIABETES_MEDS.info.emoji.ariaLabel',
            },
            title: 'DIABETES_MEDS.info.title',
            content: 'DIABETES_MEDS.info.content',
        },
    },
    INSULIN_PUMP: {
        id: 'insulinPump',
        title: 'INSULIN_PUMP.title',
        label: 'INSULIN_PUMP.label',
        placeholder: 'INSULIN_PUMP.placeholder',
        error: 'common:error.required',
    },
    INSURER_CODE: {
        id: 'insurerCode',
        type: 'text',
        title: 'INSURER_CODE.title',
        label: 'INSURER_CODE.label',
        ariaLabel: 'INSURER_CODE.ariaLabel',
        placeholder: 'INSURER_CODE.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'INSURER_CODE.info.emoji.character',
                ariaLabel: 'INSURER_CODE.info.emoji.ariaLabel',
            },
            title: 'INSURER_CODE.info.title',
            content: 'INSURER_CODE.info.content',
        },
    },
    INSURER_NAME: {
        id: 'insurerName',
        type: 'text',
        title: 'INSURER_NAME.title',
        label: 'INSURER_NAME.label',
        ariaLabel: 'INSURER_NAME.ariaLabel',
        placeholder: 'INSURER_NAME.placeholder',
        error: 'common:error.required',
    },
    LAST_NAME: {
        id: 'lastName',
        type: 'text',
        pattern: '[-0-9A-zÀ-ž ]+',
        autocomplete: 'family-name',
        title: 'LAST_NAME.title',
        label: 'LAST_NAME.label',
        placeholder: 'LAST_NAME.placeholder',
        error: 'LAST_NAME.error-lastname-format',
    },
    LDL_CHOLESTEROL_RANGE: {
        id: 'ldlCholesterolRange',
        title: 'LDL_CHOLESTEROL_RANGE.title',
        label: 'LDL_CHOLESTEROL_RANGE.label',
        placeholder: 'LDL_CHOLESTEROL_RANGE.placeholder',
        error: 'common:error.required',
    },
    LEARN_ABOUT_DIET_PRIORITY: {
        id: 'learnAboutDietPriority',
        title: 'LEARN_ABOUT_DIET_PRIORITY.title',
        label: 'LEARN_ABOUT_DIET_PRIORITY.label',
        placeholder: 'LEARN_ABOUT_DIET_PRIORITY.placeholder',
        error: 'common:error.required',
    },
    LIVONGO_CONTACT: {
        id: 'livongo_contact',
        type: 'text',
        title: 'LIVONGO_CONTACT.title',
        ariaLabel: 'LIVONGO_CONTACT.ariaLabel',
        label: 'LIVONGO_CONTACT.label',
        placeholder: 'LIVONGO_CONTACT.placeholder',
        error: 'LIVONGO_CONTACT.error-line1-empty',
        errorInvalidCharacter: 'LIVONGO_CONTACT.errorInvalidCharacter',
    },
    MEDICAL_RECORD_NUMBER: {
        id: 'medicalRecordNumber',
        type: 'text',
        title: 'MEDICAL_RECORD_NUMBER.title',
        label: 'MEDICAL_RECORD_NUMBER.label',
        placeholder: 'MEDICAL_RECORD_NUMBER.placeholder',
        error: 'common:error.required',
    },
    PASSWORD: {
        id: 'password',
        uiType: 'password',
        autocomplete: 'new-password',
        title: 'PASSWORD.title',
        label: 'PASSWORD.label',
        ariaLabel: 'PASSWORD.ariaLabel',
        placeholder: 'PASSWORD.placeholder',
        criteria1: 'PASSWORD.criteria1',
        criteria2: 'PASSWORD.criteria2',
        criteria3: 'PASSWORD.criteria3',
        criteria4: 'PASSWORD.criteria4',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'PASSWORD.info.emoji.character',
                ariaLabel: 'PASSWORD.info.emoji.ariaLabel',
            },
            title: 'PASSWORD.info.title',
            content: 'PASSWORD.info.content',
        },
    },
    PHARMACY_MEMBER_ID: {
        id: 'pharmacyMemberId',
        type: 'text',
        title: 'PHARMACY_MEMBER_ID.title',
        label: 'PHARMACY_MEMBER_ID.label',
        placeholder: 'PHARMACY_MEMBER_ID.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'PHARMACY_MEMBER_ID.info.emoji.character',
                ariaLabel: 'PHARMACY_MEMBER_ID.info.emoji.ariaLabel',
            },
            title: 'PHARMACY_MEMBER_ID.info.title',
            content: 'PHARMACY_MEMBER_ID.info.content',
        },
    },
    PHARMACY_GROUP_ID: {
        id: 'pharmacyGroupId',
        type: 'text',
        title: 'PHARMACY_GROUP_ID.title',
        label: 'PHARMACY_GROUP_ID.label',
        placeholder: 'PHARMACY_GROUP_ID.placeholder',
        error: 'common:error.required',
    },
    PHONE_HOME: {
        id: 'phoneHome',
        type: 'text',
        title: 'PHONE_HOME.title',
        label: 'PHONE_HOME.label',
        placeholder: 'PHONE_HOME.placeholder',
        error: 'common:error.required',
    },
    PHONE_MOBILE: {
        id: 'phoneMobile',
        uiType: 'phone',
        autocomplete: 'tel-national',
        title: 'PHONE_MOBILE.title',
        ariaLabel: 'PHONE_MOBILE.ariaLabel',
        label: 'PHONE_MOBILE.label',
        placeholder: 'PHONE_MOBILE.placeholder',
        error: 'PHONE_MOBILE.error-phone-valid',
    },
    PNEUMONIA_VACCINE: {
        id: 'pneumoniaVaccine',
        title: 'PNEUMONIA_VACCINE.title',
        label: 'PNEUMONIA_VACCINE.label',
        error: 'common:error.required',
    },
    POLICY_HOLDER_BIRTH_DATE: {
        id: 'policyHolderBirthDate',
        uiType: 'birthDate',
        title: 'POLICY_HOLDER_BIRTH_DATE.title',
        label: 'POLICY_HOLDER_BIRTH_DATE.label',
        placeholder: 'POLICY_HOLDER_BIRTH_DATE.placeholder',
        ariaLabel: 'POLICY_HOLDER_BIRTH_DATE.ariaLabel',
        yearAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.yearAriaLabel',
        monthAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.monthAriaLabel',
        dayAriaLabel: 'POLICY_HOLDER_BIRTH_DATE.dayAriaLabel',
        error: 'common:error.required',
    },
    POLICY_HOLDER_FIRST_NAME: {
        id: 'policyHolderFirstName',
        type: 'text',
        title: 'POLICY_HOLDER_FIRST_NAME.title',
        label: 'POLICY_HOLDER_FIRST_NAME.label',
        placeholder: 'POLICY_HOLDER_FIRST_NAME.placeholder',
        error: 'common:error.required',
    },
    POLICY_HOLDER_GROUP_ID: {
        id: 'policyHolderGroupId',
        type: 'text',
        title: 'POLICY_HOLDER_GROUP_ID.title',
        label: 'POLICY_HOLDER_GROUP_ID.label',
        ariaLabel: 'POLICY_HOLDER_GROUP_ID.ariaLabel',
        placeholder: 'POLICY_HOLDER_GROUP_ID.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'POLICY_HOLDER_GROUP_ID.info.emoji.character',
                ariaLabel: 'POLICY_HOLDER_GROUP_ID.info.emoji.ariaLabel',
            },
            title: 'POLICY_HOLDER_GROUP_ID.info.title',
            content: 'POLICY_HOLDER_GROUP_ID.info.content',
        },
    },
    POLICY_HOLDER_LAST_NAME: {
        id: 'policyHolderLastName',
        type: 'text',
        title: 'POLICY_HOLDER_LAST_NAME.title',
        label: 'POLICY_HOLDER_LAST_NAME.label',
        placeholder: 'POLICY_HOLDER_LAST_NAME.placeholder',
        error: 'common:error.required',
    },
    POLICY_HOLDER_MEMBER_ID: {
        id: 'policyHolderMemberId',
        type: 'text',
        pattern: '[-0-9A-zÀ-ž ]+',
        autocomplete: 'policy-holder-member-id',
        title: 'POLICY_HOLDER_MEMBER_ID.title',
        label: 'POLICY_HOLDER_MEMBER_ID.label',
        ariaLabel: 'POLICY_HOLDER_MEMBER_ID.ariaLabel',
        placeholder: 'POLICY_HOLDER_MEMBER_ID.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'POLICY_HOLDER_MEMBER_ID.info.emoji.character',
                ariaLabel: 'POLICY_HOLDER_MEMBER_ID.info.emoji.ariaLabel',
            },
            title: 'POLICY_HOLDER_MEMBER_ID.info.title',
            content: 'POLICY_HOLDER_MEMBER_ID.info.content',
        },
    },
    POLICY_HOLDER_NAME: {
        id: 'policyHolderName',
        type: 'text',
        title: 'POLICY_HOLDER_NAME.title',
        label: 'POLICY_HOLDER_NAME.label',
        ariaLabel: 'POLICY_HOLDER_NAME.ariaLabel',
        placeholder: 'POLICY_HOLDER_NAME.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'POLICY_HOLDER_NAME.info.emoji.character',
                ariaLabel: 'POLICY_HOLDER_NAME.info.emoji.ariaLabel',
            },
            title: 'POLICY_HOLDER_NAME.info.title',
            content: 'POLICY_HOLDER_NAME.info.content',
        },
    },
    POLICY_HOLDER_RELATIONSHIP: {
        id: 'policyHolderRelationship',
        title: 'POLICY_HOLDER_RELATIONSHIP.title',
        label: 'POLICY_HOLDER_RELATIONSHIP.label',
        placeholder: 'POLICY_HOLDER_RELATIONSHIP.placeholder',
        error: 'common:error.required',
    },
    RACE: {
        id: 'race',
        title: 'RACE.title',
        oneAppTitle: 'RACE.oneAppTitle',
        label: 'RACE.label',
        placeholder: 'RACE.placeholder',
        error: 'RACE.error',
        options: [
            {value: 'AIAN', label: 'RACE.options.AIAN'},
            {value: 'ACJK', label: 'RACE.options.ACJK'},
            {value: 'BAA', label: 'RACE.options.BAA'},
            {value: 'HLM', label: 'RACE.options.HLM'},
            {value: 'WC', label: 'RACE.options.WC'},
            {value: 'NHOPI', label: 'RACE.options.NHOPI'},
            {value: 'O', label: 'RACE.options.O'},
            {value: 'DK', label: 'RACE.options.DK'},
        ],
    },
    RACE_SUBSET: {
        id: 'raceSubset',
        type: 'text',
        title: 'RACE_SUBSET.title',
        oneAppTitle: 'RACE_SUBSET.oneAppTitle',
        label: 'RACE_SUBSET.label',
        placeholder: 'RACE_SUBSET.placeholder',
        error: 'RACE_SUBSET.error',
    },
    SMOKER: {
        id: 'smoker',
        title: 'SMOKER.title',
        label: 'SMOKER.label',
        placeholder: 'SMOKER.placeholder',
        error: 'common:error.required',
    },
    SSN4: {
        id: 'ssn4',
        type: 'text',
        title: 'SSN4.title',
        label: 'SSN4.label',
        ariaLabel: 'SSN4.ariaLabel',
        placeholder: 'SSN4.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'SSN4.info.emoji.character',
                ariaLabel: 'SSN4.info.emoji.ariaLabel',
            },
            title: 'SSN4.info.title',
            content: 'SSN4.info.content',
        },
    },
    STATE: {
        id: 'state',
        uiType: 'state',
        title: 'STATE.title',
        label: 'STATE.label',
        placeholder: 'STATE.placeholder',
        ariaLabel: 'STATE.ariaLabel',
        error: 'STATE.error-state-empty',
        translateOptions: false,
    },
    USER_ADDRESS_TYPE: {
        id: 'userAddressType',
        title: 'USER_ADDRESS_TYPE.title',
        label: 'USER_ADDRESS_TYPE.label',
        placeholder: 'USER_ADDRESS_TYPE.placeholder',
        error: 'common:error.required',
    },
    LIFESTYLE_CHANGE_DESIRED: {
        id: 'lifestyleChangeDesired',
        title: 'LIFESTYLE_CHANGE_DESIRED.title',
        label: 'LIFESTYLE_CHANGE_DESIRED.label',
        placeholder: 'LIFESTYLE_CHANGE_DESIRED.placeholder',
        error: 'common:error.required',
    },
    WEIGHT: {
        id: 'weight',
        uiType: 'weight',
        title: 'WEIGHT.title',
        oneAppTitle: 'WEIGHT.oneAppTitle',
        oneAppLabel: 'WEIGHT.oneAppLabel',
        label: 'WEIGHT.label',
        ariaLabel: 'WEIGHT.ariaLabel',
        placeholder: 'WEIGHT.placeholder',
        error: 'WEIGHT.error',
        errorInvalidCharacter: 'WEIGHT.errorInvalidCharacter',
    },
    ZIP: {
        id: 'zip',
        uiType: 'zip',
        type: 'text',
        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
        autocomplete: 'postal-code',
        title: 'ZIP.title',
        label: 'ZIP.label',
        label1: 'ZIP.label1',
        ariaLabel: 'ZIP.ariaLabel',
        placeholder: 'ZIP.placeholder',
        error: 'ZIP.error-zip-empty',
        errorInvalidCharacter: 'ZIP.error-invalid-input',
        mask: '99999',
        info: {
            emoji: {
                character: 'ZIP.info.emoji.character',
                ariaLabel: 'ZIP.info.emoji.ariaLabel',
            },
            title: 'ZIP.info.title',
            content: 'ZIP.info.content',
        },
    },
    MOUNTSINAI_DIABETES_ALLIANCE: {
        id: 'mountsinaiDiabetesAlliance',
        title: 'MOUNTSINAI_DIABETES_ALLIANCE.title',
        label: 'MOUNTSINAI_DIABETES_ALLIANCE.label',
        placeholder: 'MOUNTSINAI_DIABETES_ALLIANCE.placeholder',
        error: 'common:error.required',
    },
    MOUNTSINAI_ER_DATA: {
        id: 'mountsinaiErData',
        title: 'MOUNTSINAI_ER_DATA.title',
        label: 'MOUNTSINAI_ER_DATA.label',
        placeholder: 'MOUNTSINAI_ER_DATA.placeholder',
        error: 'common:error.required',
    },
    DIGNITY_HEALTH_TOU: {
        id: 'dignityHealthTou',
        title: 'DIGNITY_HEALTH_TOU.title',
        label: 'DIGNITY_HEALTH_TOU.label',
        placeholder: 'DIGNITY_HEALTH_TOU.placeholder',
        error: 'common:error.required',
    },
    _U_M_A_S_S__B_G__D_A_T_A: {
        id: 'umassBgData',
        uiType: 'radio',
        title: 'UMASS_BG_DATA.title',
        label: 'UMASS_BG_DATA.label',
        options: [
            {value: 'Yes', label: 'UMASS_BG_DATA.options.yes'},
            {value: 'No', label: 'UMASS_BG_DATA.options.no'},
        ],
        error: 'UMASS_BG_DATA.error',
    },
    LOCAL99_TOU: {
        id: 'local99Tou',
        title: 'LOCAL99_TOU.title',
        label: 'LOCAL99_TOU.label',
        placeholder: 'LOCAL99_TOU.placeholder',
        error: 'common:error.required',
    },
    LOCATION: {
        id: 'location',
        type: 'text',
        title: 'LOCATION.title',
        label: 'LOCATION.label',
        placeholder: 'LOCATION.placeholder',
        error: 'common:error.required',
    },
    LIVONGO_POINT_OF_CONTACT: {
        id: 'livongoPointOfContact',
        type: 'text',
        title: 'LIVONGO_POINT_OF_CONTACT.title',
        label: 'LIVONGO_POINT_OF_CONTACT.label',
        ariaLabel: 'LIVONGO_POINT_OF_CONTACT.ariaLabel',
        placeholder: 'LIVONGO_POINT_OF_CONTACT.placeholder',
        error: 'common:error.required',
    },
    EMPLOYEE_ID: {
        id: 'employeeId',
        type: 'text',
        title: 'EMPLOYEE_ID.title',
        label: 'EMPLOYEE_ID.label',
        ariaLabel: 'EMPLOYEE_ID.ariaLabel',
        placeholder: 'EMPLOYEE_ID.placeholder',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'EMPLOYEE_ID.info.emoji.character',
                ariaLabel: 'EMPLOYEE_ID.info.emoji.ariaLabel',
            },
            title: 'EMPLOYEE_ID.info.title',
            content: 'EMPLOYEE_ID.info.content',
        },
    },
    ARE_YOU_PREGNANT: {
        id: 'areYouPregnant',
        title: 'ARE_YOU_PREGNANT.title',
        oneAppTitle: 'ARE_YOU_PREGNANT.oneAppTitle',
        label: 'ARE_YOU_PREGNANT.label',
        placeholder: 'ARE_YOU_PREGNANT.placeholder',
        error: 'ARE_YOU_PREGNANT.error',
    },
    EVER_DIAGNOSED_WITH_DIABETES: {
        id: 'everDiagnosedWithDiabetes',
        title: 'EVER_DIAGNOSED_WITH_DIABETES.title',
        oneAppTitle: 'EVER_DIAGNOSED_WITH_DIABETES.oneAppTitle',
        label: 'EVER_DIAGNOSED_WITH_DIABETES.label',
        ariaLabel: 'EVER_DIAGNOSED_WITH_DIABETES.ariaLabel',
        error: 'EVER_DIAGNOSED_WITH_DIABETES.error',
    },
    EVER_DIAGNOSED_WITH_HEART_FAILURE: {
        id: 'everDiagnosedWithHeartFailure',
        title: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.title',
        label: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.label',
        ariaLabel: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.ariaLabel',
        error: 'EVER_DIAGNOSED_WITH_HEART_FAILURE.error',
    },
    EVER_DIAGNOSED_WITH_PRE_DIABETES: {
        id: 'everDiagnosedWithPreDiabetes',
        title: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.title',
        label: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.label',
        ariaLabel: 'EVER_DIAGNOSED_WITH_PRE_DIABETES.ariaLabel',
        error: 'common:error.required',
    },
    EVER_DIAGNOSED_WITH_CKD: {
        id: 'everDiagnosedWithCkd',
        title: 'EVER_DIAGNOSED_WITH_CKD.title',
        label: 'EVER_DIAGNOSED_WITH_CKD.label',
        ariaLabel: 'EVER_DIAGNOSED_WITH_CKD.ariaLabel',
        error: 'EVER_DIAGNOSED_WITH_CKD.error',
    },
    BLOOD_TEST_PREDIABETES: {
        id: 'bloodTestPrediabetes',
        title: 'BLOOD_TEST_PREDIABETES.title',
        oneAppTitle: 'BLOOD_TEST_PREDIABETES.oneAppTitle',
        label: 'BLOOD_TEST_PREDIABETES.label',
        labels: [
            'BLOOD_TEST_PREDIABETES.label_1',
            'BLOOD_TEST_PREDIABETES.label_2',
            'BLOOD_TEST_PREDIABETES.label_3',
        ],
        placeholder: 'BLOOD_TEST_PREDIABETES.placeholder',
        error: 'BLOOD_TEST_PREDIABETES.error',
    },
    RELATIVE_HAS_DIABETES: {
        id: 'relativeHasDiabetes',
        title: 'RELATIVE_HAS_DIABETES.title',
        oneAppTitle: 'RELATIVE_HAS_DIABETES.oneAppTitle',
        label: 'RELATIVE_HAS_DIABETES.label',
        placeholder: 'RELATIVE_HAS_DIABETES.placeholder',
        error: 'RELATIVE_HAS_DIABETES.error',
    },
    EVER_DIAGNOSED_WITH_GESTATIONAL_DB: {
        id: 'everDiagnosedWithGestationalDb',
        title: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.title',
        oneAppTitle: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.oneAppTitle',
        label: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.label',
        placeholder: 'EVER_DIAGNOSED_WITH_GESTATIONAL_DB.placeholder',
        error: 'common:error.required',
    },
    EVER_DIAGNOSED_WITH_HYPERTENSION: {
        id: 'everDiagnosedWithHypertension',
        title: 'EVER_DIAGNOSED_WITH_HYPERTENSION.title',
        oneAppTitle: 'EVER_DIAGNOSED_WITH_HYPERTENSION.oneAppTitle',
        label: 'EVER_DIAGNOSED_WITH_HYPERTENSION.label',
        ariaLabel: 'EVER_DIAGNOSED_WITH_HYPERTENSION.ariaLabel',
        error: 'EVER_DIAGNOSED_WITH_HYPERTENSION.error',
    },
    ARE_YOU_ACTIVE: {
        id: 'areYouActive',
        title: 'ARE_YOU_ACTIVE.title',
        oneAppTitle: 'ARE_YOU_ACTIVE.oneAppTitle',
        oneAppLabel: 'ARE_YOU_ACTIVE.oneAppLabel',
        label: 'ARE_YOU_ACTIVE.label',
        placeholder: 'ARE_YOU_ACTIVE.placeholder',
        error: 'ARE_YOU_ACTIVE.error',
    },
    GOT_SMART_PHONE: {
        id: 'gotSmartPhone',
        title: 'GOT_SMART_PHONE.title',
        oneAppTitle: 'GOT_SMART_PHONE.oneAppTitle',
        label: 'GOT_SMART_PHONE.label',
        ariaLabel: 'GOT_SMART_PHONE.ariaLabel',
        error: 'GOT_SMART_PHONE.error',
    },
    CONSENT_LABS: {
        id: 'consentLabs',
        uiType: 'radio',
        title: 'CONSENT_LABS_DATA_ACCESS.title',
        label: 'CONSENT_LABS_DATA_ACCESS.label',
        oneAppTitle: 'CONSENT_LABS_DATA_ACCESS.oneAppTitle',
        oneAppLabel: 'CONSENT_LABS_DATA_ACCESS.oneAppLabel',
        options: [
            {value: 'Yes', label: 'CONSENT_LABS_DATA_ACCESS.options.yes'},
            {value: 'No', label: 'CONSENT_LABS_DATA_ACCESS.options.no'},
        ],
        error: 'CONSENT_LABS_DATA_ACCESS.error',
    },
    EDUCATION: {
        id: 'education',
        title: 'EDUCATION.title',
        oneAppTitle: 'EDUCATION.oneAppTitle',
        oneAppLabel: 'EDUCATION.oneAppLabel',
        label: 'EDUCATION.label',
        placeholder: 'EDUCATION.placeholder',
        error: 'EDUCATION.error',
        options: [
            {value: 'PRIMARY', label: 'EDUCATION.options.PRIMARY'},
            {value: 'HS_EQUIV', label: 'EDUCATION.options.HS_EQUIV'},
            {value: 'SOME_COLLEGE', label: 'EDUCATION.options.SOME_COLLEGE'},
            {value: 'COLLEGE_GRAD', label: 'EDUCATION.options.COLLEGE_GRAD'},
            {
                value: 'DECLINE_TO_STATE',
                label: 'EDUCATION.options.DECLINE_TO_STATE',
            },
        ],
    },
    SMS_OPT_IN: {
        id: 'smsOptIn',
        uiType: 'sms',
        title: 'SMS_OPT_IN.title',
        msg: 'SMS_OPT_IN.msg',
        smsNo: 'SMS_OPT_IN.smsNo',
        smsYes: 'SMS_OPT_IN.smsYes',
        legalSMSDisclosure: 'SMS_OPT_IN.legalSMSDisclosure',
        legalSMSDisclosure2: 'SMS_OPT_IN.legalSMSDisclosure2',
        error: 'common:error.required',
    },
    LANGUAGE: {
        id: 'language',
        title: 'LANGUAGE.title',
        label: 'LANGUAGE.label',
        placeholder: 'LANGUAGE.placeholder',
        error: 'common:error.required',
    },
    EVER_HAD_VISUAL_IMPAIRMENT: {
        id: 'everHadVisualImpairment',
        title: 'EVER_HAD_VISUAL_IMPAIRMENT.title',
        label: 'EVER_HAD_VISUAL_IMPAIRMENT.label',
        placeholder: 'EVER_HAD_VISUAL_IMPAIRMENT.placeholder',
        error: 'common:error.required',
    },
    VISUAL_IMPAIRMENT_TYPE: {
        id: 'visualImpairmentType',
        title: 'VISUAL_IMPAIRMENT_TYPE.title',
        label: 'VISUAL_IMPAIRMENT_TYPE.label',
        placeholder: 'VISUAL_IMPAIRMENT_TYPE.placeholder',
        error: 'common:error.required',
    },
    PREFER_TO_USE_VOICE_ASSISTANT: {
        id: 'preferToUseVoiceAssistant',
        type: 'text',
        title: 'PREFER_TO_USE_VOICE_ASSISTANT.title',
        label: 'PREFER_TO_USE_VOICE_ASSISTANT.label',
        placeholder: 'PREFER_TO_USE_VOICE_ASSISTANT.placeholder',
        error: 'common:error.required',
    },
    LIVONGO_BETA_OPT_IN: {
        id: 'livongoBetaOptIn',
        type: 'text',
        title: 'LIVONGO_BETA_OPT_IN.title',
        label: 'LIVONGO_BETA_OPT_IN.label',
        placeholder: 'LIVONGO_BETA_OPT_IN.placeholder',
        error: 'common:error.required',
    },
    REG_CODE: {
        id: 'regCode',
        uiType: 'regCode',
        title: 'REG_CODE.title',
        placeholder: 'REG_CODE.placeholder',
        inputHint: 'REG_CODE.inputHint',
        notFoundSponsor: 'REG_CODE.notFoundSponsor',
        error: 'common:error.required',
        info: {
            emoji: {
                character: 'REG_CODE.info.emoji.character',
                ariaLabel: 'REG_CODE.info.emoji.ariaLabel',
            },
            title: 'REG_CODE.info.title',
            content: 'REG_CODE.info.content',
        },
    },
    TERMS_AND_CONDITIONS: {
        id: 'termsAndConditions',
        uiType: 'termsAndConditions',
        title: 'TERMS_AND_CONDITIONS.title',
        label: 'TERMS_AND_CONDITIONS.label',
        error: 'TERMS_AND_CONDITIONS.error-terms-empty',
    },
    ADDRESS_USA_STANDARD: {
        id: 'addressUsaStandard',
        uiType: 'address',
        title: 'ADDRESS_USA_STANDARD.title',
        info: {
            emoji: {
                character: 'ADDRESS_USA_STANDARD.info.emoji.character',
                ariaLabel: 'ADDRESS_USA_STANDARD.info.emoji.ariaLabel',
            },
            title: 'ADDRESS_USA_STANDARD.info.title',
            content: 'ADDRESS_USA_STANDARD.info.content',
            bloodGlucoseMeter: 'ADDRESS_USA_STANDARD.info.bloodGlucoseMeter',
            bloodGlucoseMeterAlt:
                'ADDRESS_USA_STANDARD.info.bloodGlucoseMeterAlt',
            bloodPressureMonitor:
                'ADDRESS_USA_STANDARD.info.bloodPressureMonitor',
            bloodPressureMonitorAlt:
                'ADDRESS_USA_STANDARD.info.bloodPressureMonitorAlt',
        },
    },
    PROGRAM: {
        id: 'program',
        uiType: 'program',
        title: 'PROGRAM.title',
        label: 'PROGRAM.label',
        placeholder: 'PROGRAM.placeholder',
        error: 'common:error.required',
    },
    TRACKING_UUID: {
        id: 'tracking_uuid',
        title: 'TRACKING_UUID.title',
        label: 'TRACKING_UUID.label',
        placeholder: 'TRACKING_UUID.placeholder',
        error: 'common:error.required',
    },
    A1C_LAST_CHECKED_U_T_C: {
        id: 'a1cLastCheckedUTC',
        uiType: 'a1cDate',
        title: 'A1C_LAST_CHECKED_DATE.title',
        label: 'A1C_LAST_CHECKED_DATE.label',
        ariaLabel: 'A1C_LAST_CHECKED_DATE.ariaLabel',
        checkboxLabel: 'A1C_LAST_CHECKED_DATE.checkboxLabel',
        yearAriaLabel: 'A1C_LAST_CHECKED_DATE.yearAriaLabel',
        monthAriaLabel: 'A1C_LAST_CHECKED_DATE.monthAriaLabel',
        yearPlaceholder: 'A1C_LAST_CHECKED_DATE.yearPlaceholder',
        monthPlaceholder: 'A1C_LAST_CHECKED_DATE.monthPlaceholder',
        error: 'common:error.required',
        errorYearEmpty: 'A1C_LAST_CHECKED_DATE.error-year-empty',
        errorMonthEmpty: 'A1C_LAST_CHECKED_DATE.error-month-empty',
    },
    FOOT_EXAM: {
        id: 'footExam',
        // title: 'FOOT_EXAM.title',
        label: 'FOOT_EXAM.label',
        placeholder: 'FOOT_EXAM.placeholder',
        error: 'common:error.required',
    },
    INJECTABLE_DIABETES_MEDS: {
        id: 'injectableDiabetesMeds',
        title: 'INJECTABLE_DIABETES_MEDS.title',
        label: 'INJECTABLE_DIABETES_MEDS.label',
        placeholder: 'INJECTABLE_DIABETES_MEDS.placeholder',
        error: 'common:error.required',
    },
    EYE_EXAM: {
        id: 'eyeExam',
        title: 'EYE_EXAM.title',
        label: 'EYE_EXAM.label',
        placeholder: 'EYE_EXAM.placeholder',
        error: 'common:error.required',
    },
    OTHER_MEDICATION: {
        id: 'footExam',
        title: 'OTHER_MEDICATION.title',
        label: 'OTHER_MEDICATION.label',
        placeholder: 'OTHER_MEDICATION.placeholder',
        error: 'common:error.required',
    },
    ORAL_DIABETES_MEDS: {
        id: 'oralDiabetesMeds',
        title: 'ORAL_DIABETES_MEDS.title',
        label: 'ORAL_DIABETES_MEDS.label',
        placeholder: 'ORAL_DIABETES_MEDS.placeholder',
        error: 'ORAL_DIABETES_MEDS.error',
    },
    INSULIN_USAGE: {
        id: 'insulinUsage',
        title: 'INSULIN_USAGE.title',
        label: 'INSULIN_USAGE.label',
        placeholder: 'INSULIN_USAGE.placeholder',
        error: 'INSULIN_USAGE.error',
    },
    A1C: {
        id: 'a1c',
        uiType: 'a1cValue',
        title: 'A1C.title',
        label: 'A1C.label',
        placeholder: 'A1C.placeholder',
        error: 'A1C.error',
        errorInvalidCharacter: 'A1C.errorInvalidCharacter',
    },
    CARDIAC_EJECTION_FRACTION: {
        id: 'cardiacEjectionFraction',
        title: 'CARDIAC_EJECTION_FRACTION.title',
        label: 'CARDIAC_EJECTION_FRACTION.label',
        ariaLabel: 'CARDIAC_EJECTION_FRACTION.ariaLabel',
        error: 'CARDIAC_EJECTION_FRACTION.error',
        options: [
            {
                value: 'OverFiftyPercent',
                label: 'CARDIAC_EJECTION_FRACTION.options.OverFiftyPercent',
            },
            {
                value: 'FortyToFortyNinePercent',
                label:
                    'CARDIAC_EJECTION_FRACTION.options.FortyToFortyNinePercent',
            },
            {
                value: 'BelowFortyPercent',
                label: 'CARDIAC_EJECTION_FRACTION.options.BelowFortyPercent',
            },
            {
                value: 'Unsure',
                label: 'CARDIAC_EJECTION_FRACTION.options.Unsure',
            },
        ],
    },
    HAS_REPORTED_DYSLIPIDEMIA: {
        id: 'hasReportedDyslipidemia',
        title: 'HAS_REPORTED_DYSLIPIDEMIA.title',
        label: 'HAS_REPORTED_DYSLIPIDEMIA.label',
        ariaLabel: 'HAS_REPORTED_DYSLIPIDEMIA.ariaLabel',
        error: 'HAS_REPORTED_DYSLIPIDEMIA.error',
    },
    HOSPITALIZATIONS_PAST_YEAR: {
        id: 'hospitalizationsPastYear',
        title: 'HOSPITALIZATIONS_PAST_YEAR.title',
        label: 'HOSPITALIZATIONS_PAST_YEAR.label',
        ariaLabel: 'HOSPITALIZATIONS_PAST_YEAR.ariaLabel',
        error: 'HOSPITALIZATIONS_PAST_YEAR.error',
        options: [
            {
                value: 'Zero',
                label: 'HOSPITALIZATIONS_PAST_YEAR.options.Zero',
            },
            {
                value: 'Once',
                label: 'HOSPITALIZATIONS_PAST_YEAR.options.Once',
            },
        ],
    },
    HAS_REPORTED_DEPRESSION: {
        id: 'hasReportedDepression',
        title: 'HAS_REPORTED_DEPRESSION.title',
        label: 'HAS_REPORTED_DEPRESSION.label',
        ariaLabel: 'HAS_REPORTED_DEPRESSION.ariaLabel',
        error: 'HAS_REPORTED_DEPRESSION.error',
    },
    HAS_REPORTED_HEART_ISSUES: {
        id: 'hasReportedHeartIssues',
        title: 'HAS_REPORTED_HEART_ISSUES.title',
        label: 'HAS_REPORTED_HEART_ISSUES.label',
        ariaLabel: 'HAS_REPORTED_HEART_ISSUES.ariaLabel',
        error: 'HAS_REPORTED_HEART_ISSUES.error',
    },
    HAS_REPORTED_DIABETES: {
        id: 'hasReportedDiabetes',
        title: 'HAS_REPORTED_DIABETES.title',
        label: 'HAS_REPORTED_DIABETES.label',
        ariaLabel: 'HAS_REPORTED_DIABETES.ariaLabel',
        error: 'HAS_REPORTED_DIABETES.error',
    },
    HAS_REPORTED_COPD: {
        id: 'hasReportedCopd',
        title: 'HAS_REPORTED_COPD.title',
        label: 'HAS_REPORTED_COPD.label',
        ariaLabel: 'HAS_REPORTED_COPD.ariaLabel',
        error: 'HAS_REPORTED_COPD.error',
    },
    HAS_REPORTED_HYPERTENSION: {
        id: 'hasReportedHypertension',
        title: 'HAS_REPORTED_HYPERTENSION.title',
        label: 'HAS_REPORTED_HYPERTENSION.label',
        ariaLabel: 'HAS_REPORTED_HYPERTENSION.ariaLabel',
        error: 'HAS_REPORTED_HYPERTENSION.error',
    },
    HAS_REPORTED_ANEMIA: {
        id: 'hasReportedAnemia',
        title: 'HAS_REPORTED_ANEMIA.title',
        label: 'HAS_REPORTED_ANEMIA.label',
        ariaLabel: 'HAS_REPORTED_ANEMIA.ariaLabel',
        error: 'HAS_REPORTED_ANEMIA.error',
    },
    HAS_REPORTED_KIDNEY_ISSUES: {
        id: 'hasReportedKidneyIssues',
        title: 'HAS_REPORTED_KIDNEY_ISSUES.title',
        label: 'HAS_REPORTED_KIDNEY_ISSUES.label',
        ariaLabel: 'HAS_REPORTED_KIDNEY_ISSUES.ariaLabel',
        error: 'HAS_REPORTED_KIDNEY_ISSUES.error',
    },
    EVER_HOSPITALIZED: {
        id: 'everHospitalized',
        title: 'EVER_HOSPITALIZED.title',
        label: 'EVER_HOSPITALIZED.label',
        ariaLabel: 'EVER_HOSPITALIZED.ariaLabel',
        error: 'EVER_HOSPITALIZED.error',
        options: [
            {
                value: 'WithinLastSixMonths',
                label: 'EVER_HOSPITALIZED.options.WithinLastSixMonths',
            },
            {
                value: 'OverSixMonthsAgo',
                label: 'EVER_HOSPITALIZED.options.OverSixMonthsAgo',
            },
            {
                value: 'OverTwelveMonthsAgo',
                label: 'EVER_HOSPITALIZED.options.OverTwelveMonthsAgo',
            },
            {
                value: 'Never',
                label: 'EVER_HOSPITALIZED.options.Never',
            },
        ],
    },
    DIAGNOSIS_RECENCY: {
        id: 'diagnosisRecency',
        title: 'DIAGNOSIS_RECENCY.title',
        label: 'DIAGNOSIS_RECENCY.label',
        ariaLabel: 'DIAGNOSIS_RECENCY.ariaLabel',
        error: 'DIAGNOSIS_RECENCY.error',
        options: [
            {
                value: 'LessThanOneYearAgo',
                label: 'DIAGNOSIS_RECENCY.options.LessThanOneYearAgo',
            },
            {
                value: 'OneToFiveYearsAgo',
                label: 'DIAGNOSIS_RECENCY.options.OneToFiveYearsAgo',
            },
            {
                value: 'OverFiveYearsAgo',
                label: 'DIAGNOSIS_RECENCY.options.OverFiveYearsAgo',
            },
            {
                value: 'Unsure',
                label: 'DIAGNOSIS_RECENCY.options.Unsure',
            },
        ],
    },
    HAS_REPORTED_HIGH_CHOLESTEROL: {
        id: 'hasReportedHighCholesterol',
        title: 'HAS_REPORTED_HIGH_CHOLESTEROL.title',
        label: 'HAS_REPORTED_HIGH_CHOLESTEROL.label',
        ariaLabel: 'HAS_REPORTED_HIGH_CHOLESTEROL.ariaLabel',
        error: 'HAS_REPORTED_HIGH_CHOLESTEROL.error',
    },
    HAS_REPORTED_ARRHYTHMIA: {
        id: 'hasReportedArrhythmia',
        title: 'HAS_REPORTED_ARRHYTHMIA.title',
        label: 'HAS_REPORTED_ARRHYTHMIA.label',
        ariaLabel: 'HAS_REPORTED_ARRHYTHMIA.ariaLabel',
        error: 'HAS_REPORTED_ARRHYTHMIA.error',
    },
};
