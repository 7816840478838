import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PasswordInput from '@teladoc/pulse/ui/PasswordInput';
import PasswordCriteria from '@teladoc/pulse/ui/PasswordCriteria';
import FormElementError from '@teladoc/pulse/ui/FormElementError';

import {questionConfig} from 'constants/questions';

import {renderLabel} from './../questions-utils';
import css from './Password.scss';

const Password = ({
    id,
    name,
    defaultValue = '',
    customValidationError,
    onChange,
    required,
}) => {
    const {t} = useTranslation('questions');
    const [value, setValue] = useState(defaultValue);
    const handleOnChange = evt => {
        setValue(evt.target.value);
        if (onChange) {
            onChange(evt);
        }
    };

    const onUpdate = isFulfilled => {
        // TODO: To enable next button
    };
    // Get question UI configuration.
    const {
        autocomplete,
        label,
        ariaLabel,
        placeholder,
        criteria1,
        criteria2,
        criteria3,
        criteria4,
        error,
    } = questionConfig[id] || {};

    return (
        <div className={css.root}>
            <PasswordInput
                {...renderLabel(t(label), t(ariaLabel))}
                id={id}
                name={name}
                defaultValue={defaultValue}
                placeholder={t(placeholder)}
                autoComplete={autocomplete}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={customValidationError}
                onChange={handleOnChange}
                required={required}
                showToggle
            />
            <div>
                <PasswordCriteria
                    value={value}
                    lowercaseLabel={t(criteria1)}
                    uppercaseLabel={t(criteria2)}
                    numeralLabel={t(criteria3)}
                    characterLabel={t(criteria4)}
                    onUpdate={onUpdate}
                    className={css.criteria}
                    labelClassName={css.criteriaLabel}
                />
            </div>
        </div>
    );
};

Password.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {SyntheticEvent} evt - The React `SyntheticEvent`.
     */
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
};

export default Password;
