import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'scrollingelement';
import 'transitionEnd';
import 'styles/index.scss';
import React from 'react';
import i18n, {BUILD_KEY, BUILD_ID, getLanguageCode} from 'i18n';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import Loader from '@teladoc/pulse/ui/Loader';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import {getStoredLang} from '@teladoc/pulse/ui/App/g11n';
import APIUtils from '@livongo/utils/api';
import MixpanelUtils from '@livongo/utils/mixpanel';
import Arg from '@livongo/arg';
import SentryUtils from '@livongo/utilities/system/sentry';
import NewRelicUtils from '@livongo/utilities/system/newrelic';
import {
    mixpanelGeneralError,
    setSuperAndPeopleProperties,
} from 'utilities/mixpanel-utils';
import {
    setVWOVariant,
    cleanSessionInfo,
    setIsOneApp,
    setCustomProgram,
    setReturnUrl,
} from 'utilities/utils';
import createStore from 'store';
import App from 'App';
import Unavailable from 'routes/unavailable';
import qs from 'qs';

const arg = Arg.all();
// TODO: Enable 'es' until we have translation files available.
const urlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
});
const lang = Arg('locale') ? getLanguageCode(Arg('locale')) : getStoredLang();
// eslint-disable-next-line no-prototype-builtins
const oneapp = urlParams.hasOwnProperty('oneapp') ? urlParams.oneapp : '';
// eslint-disable-next-line no-prototype-builtins
const program = urlParams.hasOwnProperty('program') ? urlParams.program : '';
// eslint-disable-next-line no-prototype-builtins
const subDomain = urlParams.hasOwnProperty('returnSubDomain')
    ? urlParams.returnSubDomain
    : '';
// eslint-disable-next-line no-prototype-builtins
const path = urlParams.hasOwnProperty('returnPath') ? urlParams.returnPath : '';
const authToken = urlParams.authToken;
const isDev = process.env.NODE_ENV === 'development';

if (isDev) {
    const axe = require('react-axe');
    const ReactDOM = require('react-dom');

    axe(React, ReactDOM, 1000);
}

APIUtils.init({
    baseUrl: process.env.API_URL,
    additionalHeaders: {
        'Content-Type': 'application/json',
    },
});

MixpanelUtils.init(process.env.MIXPANEL_TOKEN);

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
});

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
NewRelicUtils.init({document, nrObject});

// promises will be called in the background, paralel with rendering App component.
const promises = new Promise((resolve, reject) => {
    setIsOneApp(oneapp === 'true');
    setCustomProgram(program);
    setReturnUrl(subDomain, path);

    APIUtils.get(
        'v1/users/me/prefs/apps/1/1/lang',
        {},
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    )
        .then(response => {
            setSuperAndPeopleProperties(response.data);

            const vwoArray = [];

            cleanSessionInfo();

            for (const item in arg) {
                if (item.includes('vwo')) {
                    vwoArray.push(arg[item]);
                }
            }

            if (vwoArray.length > 0) {
                setVWOVariant(vwoArray);
            } else {
                setVWOVariant(['control']);
            }

            resolve(response.data);
        })
        .catch(error => {
            if (isDev) {
                reject(error);
            }

            MixpanelUtils.init(process.env.MIXPANEL_TOKEN);
            setSuperAndPeopleProperties();
            mixpanelGeneralError({
                name: 'Initialize Error',
                location: 'index.async.catch',
                additionalInfo: error,
            });
        });
});

render(
    <PulseApp
        promises={promises}
        build={{
            key: BUILD_KEY,
            value: BUILD_ID,
        }}
        i18n={i18n({lang})}
        render={({status}) => {
            switch (status) {
                case STATUSES.LOADING:
                    return <Loader className="loader" />;
                case STATUSES.ERROR:
                    return <Unavailable />;
                case STATUSES.INITIALIZED:
                    return (
                        <Provider store={createStore()}>
                            <App />
                        </Provider>
                    );
            }
        }}
    />,
    document.getElementById('root')
);
