import userReducer, {
    addAuthToken,
    fetchAuthTokenFromOAuth2,
    fetchPersonalInfo,
    redirectToMemberPortal,
    getUserDetails,
} from './userSlice';

export {
    addAuthToken,
    fetchAuthTokenFromOAuth2,
    fetchPersonalInfo,
    redirectToMemberPortal,
    getUserDetails,
};
export default userReducer;
