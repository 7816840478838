/**
 * This file configures the gRPC enrollment service
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment
 */
import {enrollmentPromiseClient} from 'api/protobuf/services';
import {SemanticVersion} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/semanticVersion_pb';
import {ProtocolVersion} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/protocolVersion_pb';
import {AccessToken} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/authentication/authentication_pb';
import {getUserUUID, getGrpcAccessToken} from 'utilities/utils';

const protocolVersion = new ProtocolVersion();
const semanticVersion = new SemanticVersion();
const [major, minor, patch] = process.env.GRPC_VERSION.split('.');

semanticVersion.setMajor(major);
semanticVersion.setMinor(minor);
semanticVersion.setPatch(patch);

protocolVersion.setProject(process.env.GRPC_ID);
protocolVersion.setVersion(semanticVersion);

const getApiAccessToken = () => {
    const accessToken = new AccessToken();

    accessToken.setToken(getGrpcAccessToken());

    return accessToken;
};

const getApiMetadata = () => ({'x-initial-request-uuid': getUserUUID()});

export {
    enrollmentPromiseClient,
    protocolVersion,
    getApiAccessToken,
    getApiMetadata,
};
