import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Button from '@teladoc/pulse/ui/Button';

import {messageValues} from 'constants/messages';

import css from './modalStyle.scss';

const Timeout = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const onStartOverClick = () => {
        window.open(process.env.REG_FLOW_URL, '_self');
    };

    const messageValue = messageValues[id] || {};
    const {title, content, primaryButtonText} = messageValue;

    return (
        <div className={css.modalContainer}>
            <h1>{t(title)}</h1>
            <div className={css.contentContainer}>
                <p>{t(content)}</p>
            </div>
            <Button
                className={css.modalButton}
                variant="primary"
                onClick={onStartOverClick}
            >
                {t(primaryButtonText)}
            </Button>
        </div>
    );
};

Timeout.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Timeout;
