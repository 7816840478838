import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {Redirect} from 'react-router-dom';
import Config from 'config';
import vwoConfig from 'config/experiment/vwoConfig';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import EnvironmentUtils from '@livongo/utils/environment';

import css from './ShippingConfirmation.scss';

import MyStrengthAppLinkAndBH3 from './experiment-component/GROW_1520/MyStrengthAppLinkAndBH3';

import {
    DIABETES,
    BEHAVIORAL_HEALTH,
    WEIGHT_MANAGEMENT,
    CHRONIC_KIDNEY_DISEASE,
    DIABETES_DEVICEFLEX,
    WELCOME_TOOLKITS,
} from 'constants/programs';
import messageIds from 'constants/messages';
import {
    shippingConfirmationLand,
    shippingConfirmationPass,
} from 'utilities/mixpanel-utils';
import {
    cleanSessionInfo,
    getVWOVariant,
    getIsOneApp,
    getReturnUrl,
} from 'utilities/utils';
import {ERROR_URL} from 'App';

const {ERROR_BAD_REQUEST} = messageIds;
const initData = {
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
    },
};

const ShippingConfirmation = () => {
    const {t} = useTranslation('content', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const isMobileDevice =
        EnvironmentUtils.isMobileDevice() || EnvironmentUtils.isTablet();
    const toolkits = WELCOME_TOOLKITS;
    const userStore = useSelector(state => state.user);
    const {accessCode, firstName, lastName} = userStore;
    const {addressLine1, addressLine2, city, state: addressState, zipcode} =
        userStore.address || initData.address;
    const {programs, programsSuccessfullyEnrolled} = useSelector(
        state => state.programs
    );
    const [openAddressSection, setAddressSection] = useState(true);
    const [openStartedProgramSection, setStartedProgramSection] = useState(
        false
    );

    // ************************************************************************************
    // VWO Experiment
    const {listExperimentId} = vwoConfig;
    const vwoExperiment =
        getVWOVariant() && getVWOVariant().length === 1
            ? getVWOVariant()[0]
            : 'control';
    const isVWOExperiment = listExperimentId.indexOf(vwoExperiment) > -1;
    // GROW-1520
    const grow1520VarA = vwoExperiment === 'grow1520_variant_A';
    const grow1520VarB = vwoExperiment === 'grow1520_variant_B';
    const [grow1520Experiment, setGrow1520Experiment] = useState(false);
    // ************************************************************************************

    const isProgramEnrolled = program => {
        // eslint-disable-next-line no-unused-expressions
        return (
            programs.filter(listProgram => {
                return (
                    listProgram.program === program &&
                    (listProgram.programStatus === 'ACTIVE' ||
                        listProgram.programStatus === 'REGISTERED')
                );
            }).length > 0
        );
    };
    const isProgramNotRequireWelcomeKit = programsEnrolled => {
        const BH =
            programsEnrolled.length === 1 &&
            programsEnrolled.includes(BEHAVIORAL_HEALTH);
        const DFDBT =
            programsEnrolled.length === 1 &&
            programsEnrolled.includes(DIABETES_DEVICEFLEX);
        const CKD =
            isProgramEnrolled(DIABETES) &&
            isProgramEnrolled(WEIGHT_MANAGEMENT) &&
            programsEnrolled.includes(CHRONIC_KIDNEY_DISEASE);

        return BH || CKD || DFDBT;
    };

    const {
        memberSupportPhoneFormatted,
        memberSupportPhone,
        memberPortalUrl,
        myStrengthURLPREPROD,
        myStrengthURLINT,
        myStrengthDefaultURL,
        oneAppMemberSupportPhoneFormatted,
    } = Config;
    const [isEligibleBH] = useState(isProgramEnrolled(BEHAVIORAL_HEALTH));
    const [isEligibleDBTDF] = useState(isProgramEnrolled(DIABETES_DEVICEFLEX));
    const [openCompleteSection, setCompleteSection] = useState(!isEligibleBH);

    const getMyStrengthUrl = () => {
        let url = myStrengthDefaultURL;

        if (
            process.env.NODE_ENV === 'development' ||
            window.location.host.includes('integration')
        ) {
            url = myStrengthURLINT;
        } else if (window.location.host.includes('preprod')) {
            url = myStrengthURLPREPROD;
        }

        return url;
    };

    useEffect(() => {
        shippingConfirmationLand();

        if (isProgramNotRequireWelcomeKit(programsSuccessfullyEnrolled)) {
            setAddressSection(false);
            setCompleteSection(true);
            setStartedProgramSection(isEligibleBH || isEligibleDBTDF);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const memberSupportPhoneLink = `tel:${memberSupportPhone}`;

    if (programsSuccessfullyEnrolled.includes(CHRONIC_KIDNEY_DISEASE)) {
        if (!isProgramEnrolled(DIABETES)) {
            toolkits.push({
                program: CHRONIC_KIDNEY_DISEASE,
                imgUrl:
                    '/registration/components/questions/address/bg1000_kit_esn66a.png',
                title: t(
                    'Confirmation.shippingConfirmation.toolkit.items.diabetes.title'
                ),
                description: t(
                    'Confirmation.shippingConfirmation.toolkit.items.diabetes.description'
                ),
            });
        }

        if (!isProgramEnrolled(WEIGHT_MANAGEMENT)) {
            toolkits.push({
                program: CHRONIC_KIDNEY_DISEASE,
                imgUrl:
                    '/member-portal/dashboard/home/order-status/wm1500-kit_b17248.png',
                title: t(
                    'Confirmation.shippingConfirmation.toolkit.items.weightManagement.title'
                ),
                description: t(
                    'Confirmation.shippingConfirmation.toolkit.items.weightManagement.description'
                ),
            });
        }
    }

    const isDBTDFOnly =
        programsSuccessfullyEnrolled.includes(DIABETES_DEVICEFLEX) &&
        programsSuccessfullyEnrolled.length === 1;

    const getStartedPrograms = isDBTDFOnly
        ? [
              {
                  program: DIABETES_DEVICEFLEX,
                  imgUrl: '/member-portal/programs/diabetes.png',
                  title: t(
                      'Confirmation.shippingConfirmation.getStartedSection.programs.DBTDF.title'
                  ),
                  description: t(
                      'Confirmation.shippingConfirmation.getStartedSection.programs.DBTDF.description'
                  ),
              },
          ]
        : [
              {
                  program: BEHAVIORAL_HEALTH,
                  imgUrl: '/member-portal/programs/behavioral-health.png',
                  title: t(
                      'Confirmation.shippingConfirmation.getStartedSection.programs.BH.title'
                  ),
                  description: t(
                      'Confirmation.shippingConfirmation.getStartedSection.programs.BH.description'
                  ),
                  redirectBtn: t('buttons.openMyStrength'),
                  href: getMyStrengthUrl(),
              },
          ];

    const goToStartedProgramSection = () => {
        if (isEligibleBH) {
            setAddressSection(!openAddressSection);

            if (isVWOExperiment && isMobileDevice) {
                // Variant Experiment for Mobile Device
                setStartedProgramSection(false);
                setCompleteSection(!openCompleteSection);

                // VWO Experiment
                // ****************************************************************
                setGrow1520Experiment(grow1520VarA || grow1520VarB);
                // ****************************************************************
            } else {
                // Control
                setStartedProgramSection(!openStartedProgramSection);
                setCompleteSection(!openCompleteSection);
            }
        }
    };
    const getMemberPortalURL = () => {
        let memberPortalURL =
            process.env.MEMBER_PORTAL_URL ||
            `${window.location.protocol}//${window.location.hostname.replace(
                'program',
                'my'
            )}`;

        if (process.env.NODE_ENV === 'development') {
            // Change the port to your own if it is different.
            memberPortalURL = memberPortalUrl;
            // memberPortalURL = `${memberPortalURL}:3005`;
        }

        memberPortalURL += `/?referrer=internal&access_code=${accessCode}`;

        return memberPortalURL;
    };
    const goToMyStrength = () => {
        shippingConfirmationPass('myStrength');
        cleanSessionInfo();
    };
    const goToMemberPortal = () => {
        shippingConfirmationPass('Member Portal');
        cleanSessionInfo();
        window.location.href = getMemberPortalURL();
    };

    const goToOneAppLogin = () => {
        const redirectUrl = getReturnUrl() ?? process.env.ONEAPP_LOGIN_URL;

        shippingConfirmationPass('Member Portal');
        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    if (programsSuccessfullyEnrolled.length === 0) {
        return (
            <Redirect
                to={{
                    pathname: ERROR_URL,
                    search: `?type=${ERROR_BAD_REQUEST}`,
                }}
            />
        );
    }

    const addressSection = (
        <div
            className={cn(css.root, {
                [css.oneAppRoot]: isOneApp,
            })}
        >
            <div className={css.container}>
                <div
                    className={cn(css.mainContainer, {
                        [css.oneAppMainContainer]: isOneApp,
                    })}
                >
                    <section className={css.oneAppSectionContainer}>
                        {!isOneApp && (
                            <>
                                <h1 className={css.title}>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.title'
                                    )}
                                </h1>
                                <p>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.description'
                                    )}
                                </p>
                            </>
                        )}
                        {isOneApp && (
                            <>
                                <h1>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.oneAppTitle'
                                    )}
                                </h1>
                                <p>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.oneAppDescription'
                                    )}
                                </p>
                            </>
                        )}
                    </section>
                    {isOneApp && (
                        <ul className={css.oneAppBorder}>
                            {toolkits
                                .filter(toolkit => {
                                    return (
                                        programsSuccessfullyEnrolled.filter(
                                            enrolledProgram => {
                                                return (
                                                    toolkit.program ===
                                                    enrolledProgram
                                                );
                                            }
                                        ).length === 1
                                    );
                                })
                                .map(value => {
                                    return (
                                        <li
                                            className={css.program}
                                            key={value.program}
                                        >
                                            <Image
                                                className={css.oneAppImage}
                                                cloudinaryImageId={
                                                    value.oneAppImgUrl
                                                }
                                                width={100}
                                                height={100}
                                                alt={t(value.title)}
                                            />

                                            <div
                                                className={
                                                    css.oneAppDescription
                                                }
                                            >
                                                {t(value.oneAppDescription)}
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}

                    {!isOneApp && (
                        <>
                            <section className={css.sectionContainer}>
                                <h2 className={css.subTitle}>
                                    {t(
                                        'Confirmation.shippingConfirmation.toolkit.title'
                                    )}
                                </h2>

                                <ul>
                                    {toolkits
                                        .filter(toolkit => {
                                            return (
                                                programsSuccessfullyEnrolled.filter(
                                                    enrolledProgram => {
                                                        return (
                                                            toolkit.program ===
                                                            enrolledProgram
                                                        );
                                                    }
                                                ).length === 1
                                            );
                                        })
                                        .map(value => {
                                            return (
                                                <li key={value.program}>
                                                    <Image
                                                        className={css.image}
                                                        cloudinaryImageId={
                                                            value.imgUrl
                                                        }
                                                        width={476}
                                                        height={268}
                                                        alt={t(value.title)}
                                                    />
                                                    <div
                                                        className={css.content}
                                                    >
                                                        <p>
                                                            {t(
                                                                value.description
                                                            )}
                                                        </p>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </section>
                            <section className={css.sectionContainer}>
                                <h3 className={css.subTitle}>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.address.title'
                                    )}
                                </h3>
                                <p className={css.address}>
                                    {firstName} {lastName} <br />
                                    {addressLine1} <br />
                                    {addressLine2 && addressLine2}
                                    {addressLine2 && <br />}
                                    {city}, {addressState} {zipcode}
                                </p>
                                <p className={css.updateAddress}>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.address.notes.p1'
                                    )}
                                    <a href={memberSupportPhoneLink}>
                                        {memberSupportPhoneFormatted}
                                    </a>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.address.notes.p2'
                                    )}
                                </p>
                            </section>
                        </>
                    )}

                    {isOneApp && (
                        <>
                            <div
                                className={cn(css.mainContainer, {
                                    [css.oneAppContainer]: isOneApp,
                                })}
                            >
                                <section>
                                    <h3 className={css.oneAppsubTitle}>
                                        {t(
                                            'Confirmation.shippingConfirmation.shippingSection.address.oneAppTitle'
                                        )}
                                    </h3>
                                    <p className={css.oneAppAddress}>
                                        {firstName} {lastName} <br />
                                        {addressLine1} <br />
                                        {addressLine2 && addressLine2}
                                        {addressLine2 && <br />}
                                        {city}, {addressState} {zipcode}
                                    </p>
                                </section>
                            </div>
                            <div className={css.oneAppLegalTextBlock}>
                                <p className={css.updateAddress}>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.address.notes.p3'
                                    )}

                                    <a href={memberSupportPhoneLink}>
                                        {oneAppMemberSupportPhoneFormatted}
                                    </a>
                                    {t(
                                        'Confirmation.shippingConfirmation.shippingSection.address.notes.p2'
                                    )}
                                </p>
                            </div>

                            <Button
                                variant="primary"
                                className={css.goToHomeBtn}
                                block
                                onClick={goToOneAppLogin}
                            >
                                {t('buttons.goToHomepage')}
                            </Button>
                        </>
                    )}

                    {!isOneApp && isEligibleBH && (
                        <Button
                            variant="primary"
                            block
                            onClick={goToStartedProgramSection}
                        >
                            {t('buttons.next')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );

    const startedProgramSection = (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.mainContainer}>
                    <h1 className={css.title}>
                        {t(
                            'Confirmation.shippingConfirmation.getStartedSection.title'
                        )}
                    </h1>
                    <section className={css.sectionContainer}>
                        <ul>
                            {getStartedPrograms.map(value => {
                                return (
                                    <li key={value.program}>
                                        <Image
                                            className={css.image}
                                            cloudinaryImageId={value.imgUrl}
                                            width={476}
                                            height={608}
                                            alt={t(value.title)}
                                        />
                                        <div className={css.content}>
                                            <h2>{t(value.title)}</h2>
                                            <p>{t(value.description)}</p>
                                            {value.href && (
                                                <a
                                                    href={value.href}
                                                    rel="noopener noreferrer"
                                                >
                                                    {value.redirectBtn && (
                                                        <Button
                                                            variant="primary"
                                                            block
                                                            onClick={
                                                                goToMyStrength
                                                            }
                                                        >
                                                            {value.redirectBtn}
                                                        </Button>
                                                    )}
                                                </a>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );

    const completeSection = (
        <div className={css.root}>
            <div
                className={cn(css.container, {
                    [css.oneAppMargin]: isOneApp,
                })}
            >
                {!isOneApp && (
                    <div className={css.mainContainer}>
                        <section className={css.sectionContainer}>
                            <h1 className={css.title}>
                                {t(
                                    'Confirmation.shippingConfirmation.nextSection.title'
                                )}
                            </h1>
                            <p>
                                {t(
                                    'Confirmation.shippingConfirmation.nextSection.description.p1'
                                )}
                                <a href={getMemberPortalURL()}>
                                    {t(
                                        'Confirmation.shippingConfirmation.nextSection.description.link'
                                    )}
                                </a>
                                {t(
                                    'Confirmation.shippingConfirmation.nextSection.description.p2'
                                )}
                            </p>

                            <Button
                                variant="primary"
                                block
                                onClick={goToMemberPortal}
                            >
                                {t('buttons.openLivongo')}
                            </Button>
                        </section>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <>
            {openAddressSection && addressSection}
            {openStartedProgramSection && startedProgramSection}
            {grow1520Experiment && (
                <MyStrengthAppLinkAndBH3
                    variantA={grow1520VarA}
                    variantB={grow1520VarB}
                />
            )}
            {openCompleteSection && completeSection}
        </>
    );
};

export default ShippingConfirmation;
