import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Loader from '@teladoc/pulse/ui/Loader';
import cn from 'classnames';
import {redirectToMemberPortal} from 'store/user';
import css from './Ineligible.scss';
import Config from 'config';
import {getIsOneApp, getReturnUrl, cleanSessionInfo} from 'utilities/utils';

const {oneAppMemberSupportPhoneFormatted} = Config;

const Ineligible = () => {
    const {t} = useTranslation('content', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const {authToken, accessCode, loading: isLoading} = useSelector(
        state => state.user
    );
    const {ckdDiagnosis} = useSelector(state => state.programs);
    const {memberPortalUrl, zendeskUrl} = Config;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(redirectToMemberPortal(authToken));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMemberPortalURL = () => {
        let memberPortalURL =
            process.env.MEMBER_PORTAL_URL ||
            `${window.location.protocol}//${window.location.hostname.replace(
                'program',
                'my'
            )}`;

        if (process.env.NODE_ENV === 'development') {
            // Change the port to your own if it is different.
            memberPortalURL = memberPortalUrl;
            // memberPortalURL = `${memberPortalURL}:3005`;
        }

        memberPortalURL += `/?referrer=internal&access_code=${accessCode}`;

        return memberPortalURL;
    };
    const goToMemberPortal = () => {
        cleanSessionInfo(true);
        window.location.href = getMemberPortalURL();
    };

    const goToOneAppLogin = () => {
        const redirectUrl = getReturnUrl() ?? process.env.ONEAPP_LOGIN_URL;

        cleanSessionInfo(true);
        window.location.href = redirectUrl;
    };

    return !isOneApp && isLoading ? (
        <Loader className="loader" />
    ) : (
        <div
            className={cn(css.root, {
                [css.oneAppRoot]: isOneApp,
            })}
        >
            <div className={css.container}>
                <div
                    className={cn(css.questionsContainer, {
                        [css.oneAppQuestionContainer]: isOneApp,
                    })}
                >
                    <h1
                        className={cn(css.title, {
                            [css.oneAppTitle]: isOneApp,
                        })}
                    >
                        {isOneApp
                            ? t('Ineligible.oneAppTitle')
                            : t('Ineligible.title')}
                    </h1>
                    {!ckdDiagnosis && !isOneApp && (
                        <p>
                            {t('Ineligible.message')}
                            <a href={zendeskUrl}>{t('Ineligible.url')}</a>.
                        </p>
                    )}
                    {!ckdDiagnosis && isOneApp && (
                        <p>
                            {t('Ineligible.oneAppMessage')}
                            <a
                                href={`tel:${oneAppMemberSupportPhoneFormatted}`}
                            >
                                {oneAppMemberSupportPhoneFormatted}
                            </a>
                        </p>
                    )}
                    {!isOneApp && (
                        <Button
                            variant="primary"
                            onClick={goToMemberPortal}
                            block
                        >
                            {t('buttons.openLivongo')}
                        </Button>
                    )}
                    {isOneApp && (
                        <Button
                            variant="primary"
                            className={css.goToHomeBtn}
                            onClick={goToOneAppLogin}
                            block
                        >
                            {t('buttons.goToHomepage')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Ineligible;
