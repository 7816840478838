import useMonths from '@teladoc/pulse/ui/Context/l10n/DateTime/useMonths';
import {useTranslation} from 'react-i18next';

const useAllMonths = () => {
    const {t} = useTranslation('questions');
    const {allMonths, currentYearMonths} = useMonths({
        forSelect: true,
    });

    const getMonthTranslated = months => {
        return months.map(month => {
            return {
                label: t(`common:months.${month.label}`),
                value: month.value,
            };
        });
    };

    return [getMonthTranslated(allMonths), currentYearMonths];
};

export default useAllMonths;
