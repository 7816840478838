import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {addMessage} from 'store/message/message-actions';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import {
    mobileHeartSubmitClick,
    mobileHeartRegistered,
    mobileHeartError,
} from 'utilities/mixpanel-utils';
import useAuthentication from 'hook/useAuthentication';
import {hypertensionRegistration} from 'store/programs';
import {HEART_CONFIRMATION_URL} from 'App';
import css from './HeartLanding.scss';

const HeartLanding = () => {
    const {t} = useTranslation('content', {useSuspense: false});
    const dispatch = useDispatch();
    const history = useHistory();
    const [authToken] = useAuthentication();
    const {error, statusText} = useSelector(state => state.programs);

    const showErrorMessage = () => {
        dispatch(
            addMessage({
                type: 'MEMBER_SUPPORT',
                id: 'ERROR_MOBILE_HEART',
            })
        );
    };

    const onJoinNowClick = () => {
        mobileHeartSubmitClick();
        dispatch(hypertensionRegistration(authToken));
    };

    useEffect(() => {
        if (error) {
            mobileHeartError(error.error);
            showErrorMessage();
        }
        if (statusText === 'OK') {
            mobileHeartRegistered();
            history.push(HEART_CONFIRMATION_URL);
        }
    }, [error, statusText]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.root} role="main">
            <p className={css.title}>{t('mobileHeartLanding.title')}</p>
            <Image
                className={css.image}
                cloudinaryImageId="upsell/heart/bpMonitor_u2xje0"
                width={176}
                height={176}
                alt={t('mobileHeartLanding.alt')}
            />
            <p className={css.text}>{t('mobileHeartLanding.description')}</p>
            <Button onClick={onJoinNowClick} variant="primary">
                {t('mobileHeartLanding.button')}
            </Button>
        </div>
    );
};

export default HeartLanding;
