import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import qs from 'qs';

import messageIds from 'constants/messages';
import {
    addAuthToken,
    fetchPersonalInfo,
    fetchAuthTokenFromOAuth2,
    getUserDetails,
} from 'store/user';
import {authenticationLand, authenticationPass} from 'utilities/mixpanel-utils';
import Config from 'config';
import {getUpsellURLParams} from 'utilities/utils';

const {
    // MISSING_AUTH_TOKEN,
    EXPIRED_INVALID_TOKEN,
} = messageIds;

const {memberPortalUrl} = Config;

const navigateToMemberPortal = program => {
    let memberPortalURL =
        process.env.MEMBER_PORTAL_URL ||
        `${window.location.protocol}//${window.location.hostname.replace(
            'program',
            'my'
        )}`;

    if (process.env.NODE_ENV === 'development') {
        // Change the port to your own if it is different.
        memberPortalURL = memberPortalUrl;
        // memberPortalURL = `${memberPortalURL}:3005`;
    }

    window.location = `${memberPortalURL}/login?redirect=upsell&program=${program}&${getUpsellURLParams(
        window.location.search
    )}`;
};

// if there is an authToken, fetch the user's personal info
// else set the auth token if it is in the search params or fetch it we have an OAuth2 token
// return an error message if we can't find an auth or OAuth2 token
const useAuthentication = () => {
    const dispatch = useDispatch();
    const userStore = useSelector(state => state.user);
    const {authToken, error: userStoreError} = userStore;
    const [error, setError] = useState(null);

    useEffect(() => {
        const urlParams = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const programs = urlParams.programs || urlParams.program;

        if (authToken) {
            dispatch(fetchPersonalInfo());
            dispatch(getUserDetails());
        } else if (urlParams.oauth2) {
            dispatch(fetchAuthTokenFromOAuth2(urlParams.oauth2));
        } else if (urlParams.authToken) {
            dispatch(addAuthToken(urlParams.authToken));
        } else {
            // setError(MISSING_AUTH_TOKEN);
            navigateToMemberPortal(programs);
        }
    }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        authenticationPass();
        authenticationLand();
    }, []);

    useEffect(() => {
        if (userStoreError) {
            if (userStoreError === 'invalid_token') {
                setError(EXPIRED_INVALID_TOKEN);
            }
        }
    }, [userStoreError]);

    return [authToken, error];
};

export default useAuthentication;
