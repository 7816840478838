import Image from '@teladoc/pulse/ui/Image';
import Config from 'config';
import cn from 'classnames';
import {getIsOneApp} from 'utilities/utils';
import css from './Unavailable.scss';

// There is no i18n available on this page because this is a server error and translations wouldn't have loaded yet
const TITLE = 'Uh-oh! Our site had a hiccup.';

const {
    memberSupportEmail,
    memberSupportPhone,
    memberSupportPhoneFormatted,
    oneAppMemberSupportPhoneFormatted,
    oneAppMemberSupportEmail,
} = Config;

const Unavailable = () => {
    const isOneApp = getIsOneApp();

    return (
        <div
            className={cn(css.root, {
                [css.oneAppRoot]: isOneApp,
            })}
            role="main"
        >
            {!isOneApp && (
                <Image
                    className={css.logo}
                    cloudinaryImageId="registration/common/Livongo_Logo_pulse_jqbaau"
                    width={165}
                    height={40}
                    alt=""
                />
            )}
            {isOneApp && (
                <Image
                    cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                    width={165}
                    height={40}
                    alt=""
                    showLoader={false}
                />
            )}
            <Image
                className={css.image}
                cloudinaryImageId="member-portal/common/error_aay33k"
                width={165}
                height={117}
                alt={TITLE}
            />
            <h1 className="heading-sm">{TITLE}</h1>
            <p>
                But good news ... we&apos;re working to get it back up and
                running. In the meantime, try refreshing your browser. If it
                happens again, email us at{' '}
                {!isOneApp && (
                    <>
                        <a href={`mailto:${memberSupportEmail}`}>
                            {memberSupportEmail}
                        </a>{' '}
                        or call
                        <a href={`tel:${memberSupportPhone}`}>
                            {memberSupportPhoneFormatted}
                        </a>
                    </>
                )}
                {isOneApp && (
                    <>
                        <a href={`mailto:${oneAppMemberSupportEmail}`}>
                            {oneAppMemberSupportEmail}
                        </a>{' '}
                        or call{' '}
                        <a href={`tel:${oneAppMemberSupportPhoneFormatted}`}>
                            {oneAppMemberSupportPhoneFormatted}
                        </a>
                    </>
                )}
                .
            </p>
        </div>
    );
};

export default Unavailable;
