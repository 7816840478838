/**
 * This file gathers all gRPC services used so that we can set the GRPCWEB_DEVTOOLS in one spot.
 * For now, we are using only the enrollment gPRC service
 */
import {EnrollmentPromiseClient} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/enrollment_api_grpc_web_pb';

export const enrollmentPromiseClient = new EnrollmentPromiseClient(
    process.env.SERVICE_URL
);

// if (process.env.NODE_ENV === 'development') {
// eslint-disable-next-line
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});

// enable debugging grpc calls
enableDevTools([enrollmentPromiseClient]);
// }
