import {useTranslation} from 'react-i18next';
import Config from 'config';
import Button from '@teladoc/pulse/ui/Button';

import css from './MyStrengthAppLinkAndBH3.scss';

import {shippingConfirmationPass} from 'utilities/mixpanel-utils';
import {cleanSessionInfo} from 'utilities/utils';

import {BEHAVIORAL_HEALTH} from 'constants/programs';

// eslint-disable-next-line react/prop-types
const MyStrengthAppLinkAndBH3 = ({variantA, variantB}) => {
    const isVariantB = !variantA && variantB;
    const {t} = useTranslation('content', {useSuspense: false});
    const {
        memberSupportPhone,
        myStrengthAppStoreLink,
        helpSupport,
        myStrengthDefaultURL,
        myStrengthURLINT,
        myStrengthURLPREPROD,
        memberSupportPhoneFormatted,
    } = Config;
    const memberSupportPhoneLink = `tel:${memberSupportPhone}`;
    const helpSupportLink = `mailto: ${helpSupport}`;

    const goToMyStrengthAppLink = () => {
        shippingConfirmationPass('redirecting myStrength to download app link');
        cleanSessionInfo();
    };

    const goToMyStrength = () => {
        shippingConfirmationPass('redirecting myStrength to BH3.0 web page');
        cleanSessionInfo();
    };

    const myStrengthLAppinkContent = [
        {
            program: BEHAVIORAL_HEALTH,
            description: t(
                'Confirmation.shippingConfirmation.getStartedSection.vwoExperiment.programs.myStrength.description'
            ),
            redirectBtn: t('buttons.getTheApp'),
            href: myStrengthAppStoreLink,
        },
    ];

    const getMyStrengthUrl = () => {
        let url = myStrengthDefaultURL;

        if (
            process.env.NODE_ENV === 'development' ||
            window.location.host.includes('integration')
        ) {
            url = myStrengthURLINT;
        } else if (window.location.host.includes('preprod')) {
            url = myStrengthURLPREPROD;
        }

        return url;
    };

    const myStrengthAppLink = (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.VWOmainContainer}>
                    <section className={css.myStrengthAppLinkContainer}>
                        <h1 className={css.title}>
                            {t(
                                'Confirmation.shippingConfirmation.getStartedSection.vwoExperiment.title'
                            )}
                        </h1>
                        <section className={css.sectionContainer}>
                            <ul>
                                {myStrengthLAppinkContent.map(value => {
                                    return (
                                        <li key={value.program}>
                                            <div className={css.content}>
                                                {value.title && (
                                                    <h2>{t(value.title)}</h2>
                                                )}
                                                <p>{t(value.description)}</p>
                                                <a
                                                    href={value.href}
                                                    rel="noopener noreferrer"
                                                >
                                                    {value.redirectBtn && (
                                                        <Button
                                                            color="secondary"
                                                            block
                                                            onClick={
                                                                goToMyStrengthAppLink
                                                            }
                                                        >
                                                            {value.redirectBtn}
                                                        </Button>
                                                    )}
                                                </a>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </section>
                    </section>
                    <section className={css.BH3Container}>
                        <p className={css.helpCenter}>
                            {t(
                                'Confirmation.shippingConfirmation.getStartedSection.vwoExperiment.contactInformation.p1'
                            )}{' '}
                            <a href={memberSupportPhoneLink}>
                                {memberSupportPhoneFormatted}
                            </a>{' '}
                            {t(
                                'Confirmation.shippingConfirmation.getStartedSection.vwoExperiment.contactInformation.p2'
                            )}{' '}
                            <a href={helpSupportLink}>{helpSupport}</a>.
                        </p>
                    </section>
                    {isVariantB && (
                        <section className={css.BH3ContainerButton}>
                            <a
                                href={getMyStrengthUrl()}
                                rel="noopener noreferrer"
                            >
                                <Button
                                    variant="primary"
                                    block
                                    onClick={goToMyStrength}
                                >
                                    {t('buttons.openMyStrength')}
                                </Button>
                            </a>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{myStrengthAppLink}</>;
};

export default MyStrengthAppLinkAndBH3;
