export const DIABETES = 'DIABETES';
export const HYPERTENSION = 'HYPERTENSION';
export const BEHAVIORAL_HEALTH = 'BEHAVIORAL_HEALTH';
export const WEIGHT_MANAGEMENT = 'WEIGHT_MANAGEMENT';
export const PREDIABETES = 'PREDIABETES';
export const CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE';
export const HEART_FAILURE = 'HEART_FAILURE';
export const DIABETES_DEVICEFLEX = 'DIABETES_DEVICEFLEX';

export const LIST_PROGRAM_CONFORMATION = [
    {
        program: DIABETES,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_diabetes_lg_skdu62.jpg',
            multiProgram: '/member-portal/programs/diabetes.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_dbt_lg_phamwz.png',
        title: 'Confirmation.programConfirmation.programs.diabetes.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.diabetes.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.diabetes.description',
    },
    {
        program: HYPERTENSION,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_hypertension_lg_czsfsu.jpg',
            multiProgram: '/member-portal/programs/hypertension.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_htn_lg_j3wnkh.png',
        title: 'Confirmation.programConfirmation.programs.hypertension.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.hypertension.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.hypertension.description',
    },
    {
        program: PREDIABETES,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_prediabetes_lg_zqwyny.jpg',
            multiProgram: '/member-portal/programs/prediabetes.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_dpp_lg_ubpdri.png',
        title:
            'Confirmation.programConfirmation.programs.diabetesPrevention.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.diabetesPrevention.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.diabetesPrevention.description',
    },
    {
        program: WEIGHT_MANAGEMENT,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_weight_management_lg_bh7pcn.jpg',
            multiProgram: '/member-portal/programs/weight-management.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_wm_lg_fxkiuk.png',
        title:
            'Confirmation.programConfirmation.programs.weightManagement.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.weightManagement.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.weightManagement.description',
    },
    {
        program: BEHAVIORAL_HEALTH,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_behavioral_health_lg_oaoesr.jpg',
            multiProgram: '/member-portal/programs/behavioral-health.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_bh_lg_mtnq2o.png',
        title:
            'Confirmation.programConfirmation.programs.behavioralHealth.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.behavioralHealth.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.behavioralHealth.description',
    },
    {
        program: CHRONIC_KIDNEY_DISEASE,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_weight_management_lg_bh7pcn.jpg',
            multiProgram: '/member-portal/programs/weight-management.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_ckd_lg_igme87.png',
        title: 'Confirmation.programConfirmation.programs.kidneyHealth.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.kidneyHealth.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.kidneyHealth.description',
    },
    {
        program: HEART_FAILURE,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_hypertension_lg_czsfsu.jpg',
            multiProgram: '/member-portal/programs/hypertension.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_htn_lg_j3wnkh.png',
        title: 'Confirmation.programConfirmation.programs.heartFailure.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.heartFailure.oneApTitle',
        description:
            'Confirmation.programConfirmation.programs.heartFailure.description',
    },
    {
        program: DIABETES_DEVICEFLEX,
        imgUrl: {
            singleProgram:
                '/registration/components/questions/program/program_diabetes_lg_skdu62.jpg',
            multiProgram: '/member-portal/programs/diabetes.png',
        },
        oneAppImgUrl:
            '/registration/components/questions/program/pulse/large/pulse_program_dbt_lg_phamwz.png',
        title:
            'Confirmation.programConfirmation.programs.diabetesDeviceFlex.title',
        oneAppTitle:
            'Confirmation.programConfirmation.programs.diabetesDeviceFlex.oneAppTitle',
        description:
            'Confirmation.programConfirmation.programs.diabetesDeviceFlex.description',
    },
];

export const WELCOME_TOOLKITS = [
    {
        program: DIABETES,
        imgUrl:
            '/registration/components/questions/address/bg1000_kit_esn66a.png',
        oneAppImgUrl:
            'registration/components/questions/address/BG300-oneapp_tpaqnc',
        title: 'Confirmation.shippingConfirmation.toolkit.items.diabetes.title',
        oneAppTitle:
            'Confirmation.shippingConfirmation.toolkit.items.diabetes.oneAppTitle',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.diabetes.description',
        oneAppDescription:
            'Confirmation.shippingConfirmation.toolkit.items.diabetes.oneAppDescription',
    },
    {
        program: HYPERTENSION,
        imgUrl:
            '/registration/components/questions/address/ht900_kit_livtwz.png',
        oneAppImgUrl:
            'registration/components/questions/address/HT900-oneapp_k8wa3p',
        title:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.title',
        oneAppTitle:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.oneAppTitle',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.description',
        oneAppDescription:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.oneAppDescription',
    },
    {
        program: WEIGHT_MANAGEMENT,
        imgUrl:
            '/member-portal/dashboard/home/order-status/wm1500-kit_b17248.png',
        oneAppImgUrl:
            'registration/components/questions/address/WM1500-oneapp_jodh78',
        title:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.title',
        oneAppTitle:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.oneAppTitle',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.description',
        oneAppDescription:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.oneAppDescription',
    },
    {
        program: PREDIABETES,
        imgUrl:
            '/member-portal/dashboard/home/order-status/wm1500-kit_b17248.png',
        oneAppImgUrl:
            'registration/components/questions/address/WM1500-oneapp_jodh78',
        title:
            'Confirmation.shippingConfirmation.toolkit.items.diabetesPrevention.title',
        oneAppTitle:
            'Confirmation.shippingConfirmation.toolkit.items.diabetesPrevention.oneAppTitle',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.diabetesPrevention.description',
        oneAppDescription:
            'Confirmation.shippingConfirmation.toolkit.items.diabetesPrevention.oneAppDescription',
    },
    {
        program: HEART_FAILURE,
        imgUrl:
            '/registration/components/questions/address/ht900_kit_livtwz.png',
        title:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.title',
        oneAppTitle:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.oneAppTitle',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.description',
        oneAppDescription:
            'Confirmation.shippingConfirmation.toolkit.items.hypertension.oneAppDescription',
    },
    {
        program: HEART_FAILURE,
        imgUrl:
            '/member-portal/dashboard/home/order-status/wm1500-kit_b17248.png',
        title:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.title',
        description:
            'Confirmation.shippingConfirmation.toolkit.items.weightManagement.description',
    },
];
