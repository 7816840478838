import {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Loader from '@teladoc/pulse/ui/Loader';

// import LandingPage from 'routes/landingPage';
import Program from 'routes/program';
import Unavailable from 'routes/unavailable';
import HeartLanding from 'routes/heart/heart-landing';
import HeartConfirmation from 'routes/heart/heart-confirmation';
import ErrorPage from 'routes/errorPage';
import Ineligible from 'routes/ineligible';
import EligibleShippingConfirmation from 'routes/eligible/shipping-confirmation';
import EligibleProgramConfirmation from 'routes/eligible/program-confirmation';
import Header from 'components/header';
import Footer from 'components/footer';
import Message from 'components/message/Message';

export const PROGRAM_URL = '/questions';
export const UNAVAILABLE_URL = '/unavailable';
export const ERROR_URL = '/error';
export const INELIGIBLE_URL = '/ineligible';
export const ELIGIBLE_SHIPPING_CONFIRMATION_URL =
    '/eligible/shipping-confirmation';
export const ELIGIBLE_PROGRAM_CONFIRMATION_URL = '/eligible/programs';
export const HEART_LANDING_URL = '/heart/landing';
export const HEART_CONFIRMATION_URL = '/heart/confirmation';

export const App = props => {
    return (
        <Suspense fallback={<Loader className="loader" />}>
            <Header />
            <Router>
                <Switch>
                    <Route path={UNAVAILABLE_URL} component={Unavailable} />
                    <Route path={ERROR_URL} component={ErrorPage} />
                    <Route path={INELIGIBLE_URL} component={Ineligible} />
                    <Route
                        path={ELIGIBLE_SHIPPING_CONFIRMATION_URL}
                        component={EligibleShippingConfirmation}
                    />
                    <Route
                        path={ELIGIBLE_PROGRAM_CONFIRMATION_URL}
                        component={EligibleProgramConfirmation}
                    />
                    <Route path={PROGRAM_URL} component={Program} />
                    <Route path={HEART_LANDING_URL} component={HeartLanding} />
                    <Route
                        path={HEART_CONFIRMATION_URL}
                        component={HeartConfirmation}
                    />
                    <Route path="/" component={ErrorPage} exact />
                    {/* <Route path="/" component={LandingPage} exact /> */}
                </Switch>
            </Router>
            <Footer />
            <Message />
        </Suspense>
    );
};

export default App;
