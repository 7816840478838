import {useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import css from './HeartConfirmation.scss';

const HeartConfirmation = () => {
    const {t} = useTranslation('content', {useSuspense: false});

    return (
        <div className={css.root} role="main">
            <h1 className={css.title}>{t('mobileHeartConfirmation.title')}</h1>
            <Image
                className={css.image}
                cloudinaryImageId="upsell/heart/check_fsey6j"
                width={176}
                height={176}
                alt={t('mobileHeartConfirmation.alt')}
            />
            <p className={css.text}>
                {t('mobileHeartConfirmation.description1')}
            </p>
            <p className={css.text}>
                {t('mobileHeartConfirmation.description2')}
            </p>
        </div>
    );
};

export default HeartConfirmation;
