import {messageTypes} from 'constants/messages';

export const MESSAGE_ADD = 'MESSAGE_ADD';
export const MESSAGE_ADD_CUSTOM = 'MESSAGE_ADD_CUSTOM';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

const {GENERAL, LABEL} = messageTypes;

/**
 * Present a message based on messageId from 'constants/messages
 * The type determines with subComponent of message to use and id determines which copy to use inside 'constants/messages'
 * @param {obj} message - message object containing a type and id
 * @returns {obj} - Action
 */
export const addMessage = ({type = GENERAL, id}) => {
    return {
        type: MESSAGE_ADD,
        payload: {
            type,
            id,
        },
    };
};

/**
 * Presents a custom message that based on messageId,
 * @param {string} text - The text key to display, still needs to be translated
 * @returns {string} - Action
 */
export const addCustomMessage = ({type = LABEL, text}) => {
    return {
        type: MESSAGE_ADD_CUSTOM,
        payload: {
            type,
            text,
        },
    };
};

export const clearMessage = () => {
    return {
        type: MESSAGE_CLEAR,
    };
};
