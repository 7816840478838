import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Label from '@teladoc/pulse/ui/Label';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import {questionConfig} from 'constants/questions';
import Config from 'config';
import css from './Sms.scss';

const {memberSupportPhoneFormatted, textStop} = Config;

const Sms = ({id, name, required, defaultValue, customValidationError}) => {
    const [showLegal, setLegal] = useState(false);
    const {t} = useTranslation('questions');
    const {msg, smsYes, smsNo, legalSMSDisclosure, legalSMSDisclosure2} =
        questionConfig[id] || {};

    return (
        <div>
            <p>{t(msg)}</p>

            <section>
                <RadioGroup
                    id={id}
                    name={name}
                    label={
                        <Label>
                            <p />
                        </Label>
                    }
                >
                    <Radio
                        key="Yes"
                        id="Yes"
                        value="Yes"
                        title={t('common:options.Yes')}
                        onClick={() => {
                            setLegal(true);
                        }}
                        label={<Label>{t(smsYes)}</Label>}
                    />
                    <Radio
                        key="No"
                        id="No"
                        value="No"
                        title={t('common:options.NO')}
                        onClick={() => {
                            setLegal(false);
                        }}
                        label={<Label>{t(smsNo)}</Label>}
                    />
                </RadioGroup>
            </section>

            {showLegal ? (
                <div>
                    <p className={css.p}>{t(legalSMSDisclosure)}</p>
                    <p>
                        {t(legalSMSDisclosure2, {
                            memberSupportPhoneFormatted,
                            textStop,
                        })}
                    </p>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

Sms.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultValue: PropTypes.string,
    customValidationError: PropTypes.element,
};

export default Sms;
