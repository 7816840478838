import {Date} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/date_pb';
import {TypedValue} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/typed_value_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/question_ids_pb';
import {Answer} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/questions/answer_pb';

import {errorMessage} from 'utilities/errors-utils';
import {questionFieldTypes} from 'constants/questions';

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
} = questionFieldTypes;

/**
 * answerToProtobuf
 * Converts an answer from a javascript object to gRPC
 * @param {Object} ans answers to be sent to the server
 *  example:
 *       {
 *           questionId: {
 *               id: 21, // FIRST_NAME
 *               version: 1,
 *           },
 *           type: TypedField.FieldTypeCase.TEXT,
 *           value: 'Michael',
 *       },
 * @return {proto.com.livongo.protobuf.grpc.external.enrollment.questions.Answer} answer in gRPC format
 */
export const answerToProtobuf = ans => {
    if (!ans) {
        throw new Error(errorMessage('Missing answer', ans));
    }
    if (!ans.questionId) {
        throw new Error(errorMessage('Missing questionId for answer', ans));
    }
    if (!ans.questionId.id) {
        throw new Error(errorMessage('Missing questionId.id for answer', ans));
    }
    if (!ans.questionId.version) {
        throw new Error(
            errorMessage('Missing questionId.version for answer', ans)
        );
    }

    const questionId = new QuestionId();
    const typedValue = new TypedValue();
    const answer = new Answer();

    questionId.setId(ans.questionId.id);
    questionId.setVersion(ans.questionId.version);

    // switch to make the right call depending on the answer's data type
    // additional data parsing is done to make sure we send the data in the right format
    // TODO: Add error logging to mixpanel or logging
    switch (ans.type) {
        case TEXT_FIELD:
            typedValue.setText(ans.value.toString());
            break;
        case BOOLEAN_FIELD:
            typedValue.setBoolean(ans.value === true || ans.value === 'true');
            break;
        case INTEGER_FIELD:
            typedValue.setInteger(parseInt(ans.value, 10));
            break;
        case FLOAT_FIELD:
            typedValue.setFloat(parseFloat(ans.value));
            break;
        case DATE_FIELD: {
            const [year, month, day = '1'] = ans.value.split('-');
            const date = new Date();

            date.setYear(year);
            date.setMonth(month);
            date.setDay(day);

            typedValue.setDate(date);
            break;
        }
        case SINGLE_SELECT_ENUM:
            typedValue.setSingleSelectEnumCode(ans.value);
            break;
        case MULTI_SELECT_ENUM: {
            const repeatedEnum = new TypedValue.RepeatedEnum();

            repeatedEnum.setEnumCodeList(ans.value);
            typedValue.setMultiSelectEnum(repeatedEnum);
            break;
        }
        case COMPOUND_FIELD: {
            const compoundValue = new TypedValue.CompoundValue();

            typedValue.setCompound(compoundValue);
            ans.subValues.forEach(subAns => {
                compoundValue.addSubValues(answerToProtobuf(subAns));
            });
            break;
        }
        default:
            throw new Error(
                errorMessage('Unsupported data type for answer', ans)
            );
    }

    answer.setQuestionId(questionId);
    answer.setValue(typedValue);

    return answer;
};
