import {combineReducers} from 'redux';
import message from './message/message-reducer';
import user from './user';
import programs from './programs';
import questions from './questions';

export default combineReducers({
    user,
    message,
    programs,
    questions,
});
