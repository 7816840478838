import {
    MESSAGE_ADD,
    MESSAGE_ADD_CUSTOM,
    MESSAGE_CLEAR,
} from './message-actions';

// type is of type: constants/messages/messageTypes
// id is of type: constants/messages/messageIds
const defaultState = {
    type: null,
    id: null,
    text: null,
};

const message = (state = defaultState, {type, payload = {}}) => {
    switch (type) {
        case MESSAGE_ADD: {
            const {type: messageType = null, id} = payload;

            return {
                type: messageType,
                id,
                text: null,
            };
        }
        case MESSAGE_ADD_CUSTOM: {
            const {type: messageType = null, text = null} = payload;

            return {
                type: messageType,
                id: null,
                text,
            };
        }
        case MESSAGE_CLEAR: {
            return defaultState;
        }
        default:
            return state;
    }
};

export default message;
