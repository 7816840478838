import {useEffect, useState} from 'react';
import Image from '@teladoc/pulse/ui/Image';
import Arg from '@livongo/arg';
import {getIsOneApp} from 'utilities/utils';

import Config from 'config';
// import messageIds from 'constants/messages';

import css from './ErrorPage.scss';

// const {MISSING_AUTH_TOKEN, EXPIRED_INVALID_TOKEN} = messageIds;
const TITLE = 'Uh-oh! Our site had a hiccup.';

const renderBaseErrorMessage = () => {
    const isOneApp = getIsOneApp();
    const {
        memberSupportEmail,
        memberSupportPhone,
        memberSupportPhoneFormatted,
        oneAppMemberSupportPhoneFormatted,
        oneAppMemberSupportEmail,
    } = Config;

    return (
        <p>
            But good news ... we&apos;re working to get it back up and running.
            In the meantime, try refreshing your browser. If it happens again,
            email us at{' '}
            {!isOneApp && (
                <>
                    <a href={`mailto:${memberSupportEmail}`}>
                        {memberSupportEmail}
                    </a>{' '}
                    or call{' '}
                    <a href={`tel:${memberSupportPhone}`}>
                        {memberSupportPhoneFormatted}
                    </a>
                </>
            )}
            {isOneApp && (
                <>
                    <a href={`mailto:${oneAppMemberSupportEmail}`}>
                        {oneAppMemberSupportEmail}
                    </a>{' '}
                    or call{' '}
                    <a href={`tel:${oneAppMemberSupportPhoneFormatted}`}>
                        {oneAppMemberSupportPhoneFormatted}
                    </a>
                </>
            )}
            .
        </p>
    );
};

const renderErrorMessage = errorType => {
    let copy;

    switch (errorType) {
        case 'missingAuthToken':
            copy = 'Missing Authorization Token';
            break;
        case 'invalid_token':
            copy = 'Missing Authorization Token';
            break;
    }

    if (copy) {
        return <p>{copy}</p>;
    }

    return renderBaseErrorMessage();
};

const ErrorPage = () => {
    const isOneApp = getIsOneApp();
    const [errorType, setErrorType] = useState(null);

    useEffect(() => {
        setErrorType(Arg('type'));
    }, []);

    return (
        <div className={css.root} role="main">
            {!isOneApp && (
                <Image
                    className={css.logo}
                    cloudinaryImageId="registration/common/Livongo_Logo_pulse_jqbaau"
                    width={165}
                    height={40}
                    alt=""
                />
            )}
            {isOneApp && (
                <Image
                    cloudinaryImageId="registration/common/Teladoc_Logo_ES.svg"
                    width={125}
                    height={40}
                    alt=""
                    showLoader={false}
                />
            )}
            <Image
                className={css.image}
                cloudinaryImageId="member-portal/common/error_aay33k"
                width={164}
                height={117}
                alt={TITLE}
            />
            <h1 className="heading-sm">{TITLE}</h1>
            {renderErrorMessage(errorType)}
        </div>
    );
};

export default ErrorPage;
