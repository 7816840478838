import StorageUtils from '@livongo/utils/storage';
import Config from '../config';
import VWOConfig from '../config/experiment/vwoConfig';
import qs from 'qs';

const {
    stepUrls,
    stepIndex,
    stepLoader,
    userUUID,
    userSessionExpires,
    grpcAccessToken,
    clientFriendlyName,
    isOneApp,
    customProgram,
    returnUrl,
} = Config;

const {vwoVariant} = VWOConfig;

export const camelToUnderscoreCase = str => {
    return str.replace(/[A-Z]/g, letter => `_${letter}`);
};

/**
 * Get object property name by value.
 * @param {object} obj - The object look for.
 * @param {object} value - The value to compare.
 * @returns {string|null} - The property name.
 */
export const getObjectKeyByValue = (obj, value) => {
    try {
        for (const key in obj) {
            if (obj[key] === value) {
                return key;
            }
        }

        return null;
    } catch (e) {
        return null;
    }
};

/**
 * Get the first non-empty property name of an object
 * @param {object} obj - The object look for.
 * @returns {string|null} - The property name.
 */
export const getFirstNonEmptyKey = obj => {
    for (const key in obj) {
        if (obj[key] !== null && obj[key]) {
            return key;
        }
    }

    return null;
};

export const extendStorageUtils = () => {
    StorageUtils.batchSet = objArray => {
        objArray.forEach(obj => {
            StorageUtils.set({
                key: obj.key,
                value: obj.value,
            });
        });
    };

    StorageUtils.batchRemove = objArray => {
        objArray.forEach(obj => {
            StorageUtils.remove({
                key: obj.key,
            });
        });
    };
};

export const getRegistrationStepUrls = () => {
    return (
        StorageUtils.get({
            key: stepUrls,
        }) || []
    );
};

export const getRegistrationStepIndex = () => {
    const index = StorageUtils.get({
        key: stepIndex,
    });

    return index === null ? -1 : index;
};

export const getRegistrationHistory = () => {
    return {
        stepUrls: getRegistrationStepUrls(),
        stepIndex: getRegistrationStepIndex(),
    };
};

export const getRegistrationStepLoader = () => {
    return StorageUtils.get({
        key: stepLoader,
    });
};

export const setRegistrationStepUrls = urls => {
    StorageUtils.set({
        key: stepUrls,
        value: urls,
    });
};

export const setRegistrationStepIndex = index => {
    StorageUtils.set({
        key: stepIndex,
        value: index,
    });
};

export const setRegistrationHistory = (urls, index) => {
    setRegistrationStepUrls(urls);
    setRegistrationStepIndex(index);
};

export const setRegistrationStepLoader = loader => {
    StorageUtils.set({
        key: stepLoader,
        value: loader,
    });
};

export const getUserUUID = () => {
    return StorageUtils.get({
        key: userUUID,
    });
};

export const setUserUUID = uuid => {
    StorageUtils.set({
        key: userUUID,
        value: uuid,
    });
};

export const getUserSessionExpires = () => {
    return StorageUtils.get({
        key: userSessionExpires,
    });
};

export const setUserSessionExpires = time => {
    StorageUtils.set({
        key: userSessionExpires,
        value: time,
    });
};

export const getGrpcAccessToken = () => {
    return StorageUtils.get({
        key: grpcAccessToken,
    });
};

export const setGrpcAccessToken = token => {
    StorageUtils.set({
        key: grpcAccessToken,
        value: token,
    });
};

export const getClientFriendlyName = () => {
    return StorageUtils.get({
        key: clientFriendlyName,
    });
};

export const setClientFriendlyName = name => {
    StorageUtils.set({
        key: clientFriendlyName,
        value: name,
    });
};

export const getVWOVariant = () => {
    return StorageUtils.get({
        key: vwoVariant,
    });
};

export const setVWOVariant = vwo => {
    StorageUtils.set({
        key: vwoVariant,
        value: vwo,
    });
};

export const getCustomProgram = () => {
    return StorageUtils.get({
        key: customProgram,
    });
};

export const setCustomProgram = program => {
    StorageUtils.set({
        key: customProgram,
        value: program,
    });
};

export const getIsOneApp = () => {
    return StorageUtils.get({
        key: isOneApp,
    });
};

export const setIsOneApp = oneapp => {
    StorageUtils.set({
        key: isOneApp,
        value: oneapp,
    });
};

export const getReturnUrl = () => {
    return StorageUtils.get({
        key: returnUrl,
    });
};

export const setReturnUrl = (subDomain, path) => {
    let newUrl;

    if (process.env.ENVIRONMENT === 'production') {
        newUrl = `https://${subDomain}.teladoc.com${path}`;
    } else {
        newUrl = `https://${subDomain}${path}`;
    }

    StorageUtils.set({
        key: returnUrl,
        value: newUrl,
    });
};

// export const setVWOVariant = uuid => {};

export const cleanSessionInfo = () => {
    StorageUtils.remove({key: stepUrls});
    StorageUtils.remove({key: stepIndex});
    StorageUtils.remove({key: stepLoader});
    StorageUtils.remove({key: userUUID});
    StorageUtils.remove({key: userSessionExpires});
    StorageUtils.remove({key: grpcAccessToken});
    StorageUtils.remove({key: vwoVariant});
};

export const isCharacterALetter = char => {
    return /^[A-zÀ-ž][A-zÀ-ž '’.-]+\s[A-zÀ-ž][A-zÀ-ž '’.-]+$/.test(char);
};

export const getUpsellURLParams = queryParams => {
    const urlParams = qs.parse(queryParams, {
        ignoreQueryPrefix: true,
    });

    delete urlParams.authToken;
    delete urlParams.program;

    return qs.stringify(urlParams);
};
