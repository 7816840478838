import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import findIndex from 'lodash/findIndex';
import qs from 'qs';
import format from 'date-fns/format';
// eslint-disable-next-line node/no-extraneous-import
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import Button from '@teladoc/pulse/ui/Button';

import {
    ELIGIBLE_PROGRAM_CONFIRMATION_URL,
    INELIGIBLE_URL,
    ERROR_URL,
} from 'App';
import {
    DIABETES,
    HYPERTENSION,
    BEHAVIORAL_HEALTH,
    WEIGHT_MANAGEMENT,
    PREDIABETES,
    CHRONIC_KIDNEY_DISEASE,
    HEART_FAILURE,
    DIABETES_DEVICEFLEX,
} from 'constants/programs';
import messageIds from 'constants/messages';
import {questionConfig} from 'constants/questions';
import useAuthentication from 'hook/useAuthentication';
import {
    addMessage,
    // addCustomMessage
} from 'store/message/message-actions';
import {programUpsell, clientProfile} from 'store/programs';
import {fetchQuestions} from 'store/questions';
import {
    camelToUnderscoreCase,
    isCharacterALetter,
    getCustomProgram,
    getIsOneApp,
} from 'utilities/utils';
import Config from 'config';
import {
    questionsLand,
    questionsPass,
    programsQualified,
    programsDisqualified,
    programPass,
} from 'utilities/mixpanel-utils';
import FloatingCard from 'components/floatingCard';
import UpsellQuestion from 'components/question/UpsellQuestion';
import css from './Program.scss';

const {
    // MISSING_AUTH_TOKEN,
    // EXPIRED_INVALID_TOKEN,
    MISSING_PROGRAM_PARAM,
} = messageIds;

const {memberPortalUrl} = Config;

const navigateToMemberPortal = program => {
    let memberPortalURL =
        process.env.MEMBER_PORTAL_URL ||
        `${window.location.protocol}//${window.location.hostname.replace(
            'program',
            'my'
        )}`;

    if (process.env.NODE_ENV === 'development') {
        // Change the port to your own if it is different.
        memberPortalURL = memberPortalUrl;
        // memberPortalURL = `${memberPortalURL}:3005`;
    }

    window.location = `${memberPortalURL}/login?redirect=upsell&program=${program}`;
};

// If there are questionGroups, display them first and together
const sortQuestions = questions => {
    const groups = {};
    const noGroup = [];
    let returnValue = [];

    questions.forEach(question => {
        if (question.questionGroup) {
            if (!groups[question.questionGroup]) {
                groups[question.questionGroup] = [];
            }
            groups[question.questionGroup].push(question);
        } else {
            noGroup.push(question);
        }
    });

    for (const group in groups) {
        if (Object.prototype.hasOwnProperty.call(groups, group)) {
            // Reorder the personal information group
            const newGroup = [];
            let idealOrder = [];

            if (group === 'personal_information') {
                idealOrder = [
                    'addressLine1',
                    'addressLine2',
                    'city',
                    'state',
                    'zip',
                ];

                idealOrder.forEach(key => {
                    const index = findIndex(groups[group], question => {
                        return question.id === key;
                    });

                    if (index !== -1) {
                        newGroup.push(groups[group].splice(index, 1)[0]);
                    }
                });
                groups[group] = newGroup.concat(groups[group]);
            } else if (group === 'None') {
                idealOrder = ['height', 'weight', 'a1c', 'a1cLastCheckedUTC'];

                idealOrder.forEach(key => {
                    const index = findIndex(groups[group], question => {
                        return question.id === key;
                    });

                    if (index !== -1) {
                        newGroup.push(groups[group].splice(index, 1)[0]);
                    }
                });
                groups[group].splice(2, 0, ...newGroup);
            }

            returnValue = returnValue.concat(groups[group]);
        }
    }

    return returnValue.concat(noGroup);
};

const checkInvalidCharacter = (questionId, answer) => {
    switch (questionId) {
        case 'addressLine1':
        case 'addressLine2':
            if (answer.length > 35) {
                return true;
            }
            break;
        case 'zip':
            // eslint-disable-next-line no-case-declarations
            const {pattern} = questionConfig[questionId.toUpperCase()];

            return !new RegExp(pattern).test(answer);
        case 'city':
            if (answer.length > 100) {
                return true;
            }
            break;
        case 'phoneMobile':
            if (!isPossiblePhoneNumber(answer)) {
                return true;
            }
            break;
        case 'weight':
            if (parseFloat(answer) < 1 || parseFloat(answer) > 440) {
                return true;
            }
            break;
        case 'a1c':
            if (parseFloat(answer) < 2 || parseFloat(answer) > 25) {
                return true;
            }
            break;
        case 'livongo_contact':
            if (!isCharacterALetter(answer)) {
                return true;
            }
            break;
        default:
            break;
    }

    return false;
};

// Check if there is an answer for each required question, return a hash with all the questions not answered and matching error keys
const checkAnswers = (questions, answerHash) => {
    const errors = {};

    questions.forEach(question => {
        const questionName = camelToUnderscoreCase(question.id).toUpperCase();

        if (
            question.necessity === 'REQUIRED' &&
            (!answerHash[question.id] || answerHash[question.id] === '')
        ) {
            errors[question.id] =
                questionConfig[questionName]?.error || 'common:error.required';
        }

        if (
            answerHash[question.id] &&
            checkInvalidCharacter(question.id, answerHash[question.id])
        ) {
            errors[question.id] =
                questionConfig[questionName]?.errorInvalidCharacter ||
                questionConfig[questionName]?.error ||
                'common:error.generalProblem';
        }

        if (
            question.id === 'a1cLastCheckedUTC' &&
            (!answerHash[question.id] || answerHash[question.id] === '')
        ) {
            errors[question.id] =
                questionConfig[questionName]?.error || 'common:error.required';
        }
    });

    return errors;
};

const checkGeneralRequiredQuestionLength = generalQs => {
    return generalQs.filter(question => {
        return question.necessity === 'REQUIRED';
    }).length;
};

const Program = ({history, location}) => {
    const {t} = useTranslation('programs', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const dispatch = useDispatch();
    // get the auth token and user information
    const [authToken, authenticationError] = useAuthentication();
    const {
        firstName,
        lastName,
        birthDate,
        details: {regCode},
    } = useSelector(state => state.user);
    const {
        qualificationQs,
        generalQs,
        // loading, // TODO: adding loading icon
        error: questionsError,
    } = useSelector(state => state.questions);
    const {
        programsSuccessfullyEnrolled,
        error: programsError,
        validations: validationsError,
    } = useSelector(state => state.programs);

    // const [programs, setPrograms] = useState(''); Support multi-program
    const [program, setProgram] = useState('');
    const [qualificationStage, setQualificationStage] = useState(true);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});

    const onChange = (key, value) => {
        setAnswers({
            ...answers,
            [key]: value,
        });

        if (errors[key]) {
            delete errors[key];
            setErrors(errors);
        }
    };

    const onSubmit = () => {
        const questionErrors = checkAnswers(
            qualificationStage ? qualificationQs : generalQs,
            answers
        );

        if (Object.keys(questionErrors).length) {
            return setErrors(questionErrors);
        }
        const isGeneralRequiredQuestion =
            checkGeneralRequiredQuestionLength(generalQs) > 0;

        // change to general questions only when user answered yes
        // if answered no, can directly move forward and call programUpsell then to show dq page

        if (
            qualificationStage &&
            isGeneralRequiredQuestion &&
            (answers.everDiagnosedWithDiabetes === 'true' ||
                answers.everDiagnosedWithHypertension === 'true')
        ) {
            window.scrollTo(0, 0);
            setQualificationStage(false);
        } else {
            const programAnswers = [
                {
                    questionId: 'firstName',
                    answer: firstName,
                },
                {
                    questionId: 'lastName',
                    answer: lastName,
                },
                {
                    questionId: 'birthDate',
                    answer: format(new Date(birthDate), 'yyyy-MM-dd'),
                },
            ];

            const addAnswer = question => {
                let answer = answers[question.id];

                if (answer === 'IDK') {
                    return;
                }

                if (answer || answer === false) {
                    if (question.answerType === 'BOOLEAN') {
                        // convert to a boolean type
                        answer =
                            answers[question.id] === true ||
                            (typeof answers[question.id] === 'string' &&
                                answers[question.id].toLowerCase() === 'true');
                    } else if (
                        question.answerType === 'INT' ||
                        question.answerType === 'FLOAT' ||
                        question.answerType === 'DOUBLE'
                    ) {
                        if (isNaN(answer)) {
                            // TODO: error message
                        }
                        answer = Number(answer);
                    }

                    programAnswers.push({
                        questionId: question.id,
                        answer,
                    });
                }
            };

            qualificationQs.forEach(addAnswer);
            generalQs.forEach(addAnswer);

            questionsPass();

            /* Support multi-program
            dispatch(
                programUpsell({
                    programs,
                    answers: programAnswers,
                })
             );
            */

            dispatch(
                programUpsell({
                    program,
                    answers: programAnswers,
                })
            );
        }
    };

    // On page load, verify the programs passed into the URL are valid
    useEffect(() => {
        if (authToken) {
            const urlParams = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            });

            // set the program and fetch the personal info
            const programsForQuestions =
                urlParams.programs || urlParams.program;
            let errorFound = false;

            if (!programsForQuestions) {
                errorFound = true;
            } else {
                /* Support multi-program
                programsForQuestions = programsForQuestions.split(',');
                programsForQuestions.forEach(program => {
                    if (
                        ![
                            DIABETES,
                            HYPERTENSION,
                            BEHAVIORAL_HEALTH,
                            WEIGHT_MANAGEMENT,
                            PREDIABETES,
                            CHRONIC_KIDNEY_DISEASE,
                            HEART_FAILURE,
                            DIABETES_DEVICEFLEX,
                        ].includes(program)
                    ) {
                        errorFound = true;
                    }
                });
                */

                if (
                    ![
                        DIABETES,
                        HYPERTENSION,
                        BEHAVIORAL_HEALTH,
                        WEIGHT_MANAGEMENT,
                        PREDIABETES,
                        CHRONIC_KIDNEY_DISEASE,
                        HEART_FAILURE,
                        DIABETES_DEVICEFLEX,
                    ].includes(programsForQuestions)
                ) {
                    errorFound = true;
                }
            }

            if (errorFound) {
                dispatch(
                    addMessage({
                        id: MISSING_PROGRAM_PARAM,
                    })
                );
            } else {
                /* Support multi-program
                    setPrograms(programsForQuestions);
                    dispatch(fetchQuestions(programsForQuestions.join(',')));
                */

                setProgram(programsForQuestions);
                dispatch(fetchQuestions(programsForQuestions));
            }
        }

        // eslint-disable-next-line
    }, [authToken]);

    // On page load, get client code profile
    useEffect(() => {
        if (regCode) {
            const urlParams = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            });

            dispatch(
                clientProfile({clientCode: regCode, program: urlParams.program})
            );
        }

        // eslint-disable-next-line
    }, [regCode]);

    // Page onload to capture Mixpanel - Question Land
    useEffect(() => {
        if (
            qualificationQs &&
            generalQs &&
            Array.isArray(qualificationQs) &&
            qualificationQs.length > 0 &&
            Array.isArray(generalQs)
        ) {
            const allQuestions = qualificationQs.concat(generalQs);
            const getAllQuestions = allQuestions
                .filter(questions => {
                    const {necessity} = questions;

                    return necessity === 'REQUIRED';
                })
                .map(questions => {
                    const {id} = questions;

                    return id;
                });

            questionsLand(getAllQuestions);
        }
    }, [qualificationQs]); // eslint-disable-line react-hooks/exhaustive-deps

    // Display an error if we have one with Program Upsell
    useEffect(() => {
        const errorMessage = programsError || questionsError;

        if (errorMessage) {
            if (errorMessage === 'invalid_token') {
                // dispatch(addMessage({id: EXPIRED_INVALID_TOKEN}));
                navigateToMemberPortal(program);
            } else {
                const urlParams = qs.parse(location.search, {
                    ignoreQueryPrefix: true,
                });
                const reasons = validationsError
                    ? validationsError.map(validationError => {
                          return validationError.message;
                      })
                    : errorMessage;

                const listProgramsDisqualified =
                    urlParams.programs || urlParams.program;

                // listProgramsDisqualified = listProgramsDisqualified.split(','); Support multi-program

                programsDisqualified(listProgramsDisqualified, reasons);
                history.push(INELIGIBLE_URL);
                // dispatch(addCustomMessage({text: errorMessage}));
            }
        }

        // eslint-disable-next-line
    }, [programsError, questionsError]);

    // Handle authentication and personal info error state
    if (authenticationError) {
        return (
            <Redirect
                to={{
                    pathname: ERROR_URL,
                    search: `?type=${authenticationError}`,
                }}
            />
        );
    }

    // If the user has successfully enrolled in a program, send them to the confirmation page
    if (programsSuccessfullyEnrolled.length > 0) {
        programPass();
        programsQualified(programsSuccessfullyEnrolled);

        return (
            <Redirect
                to={{
                    pathname: ELIGIBLE_PROGRAM_CONFIRMATION_URL,
                }}
            />
        );
    }

    let questionsToUse = qualificationStage ? qualificationQs : generalQs;

    questionsToUse = sortQuestions(questionsToUse);

    const optionalQuestion = id => {
        return id === 'addressLine2' || id === 'a1cLastCheckedUTC';
    };

    const programConstant = {
        BEHAVIORAL_HEALTH: 'Behavioral Health',
        WEIGHT_MANAGEMENT: 'Weight Management',
        HYPERTENSION: 'Hypertension',
        DIABETES: 'Diabetes Management',
        PREDIABETES: 'Healthy Living and Diabetes Prevention',
        HEART_FAILURE: 'Heart Health',
        CHRONIC_KIDNEY_DISEASE: 'Kidney Health',
        DIABETES_DEVICEFLEX: 'Diabetes Management',
    };

    const subtitle = t('program.oneAppSubtitle').replace(
        'custom',
        programConstant[getCustomProgram()]
    );

    return (
        <div
            className={cn(css.root, {
                [css.oneApproot]: isOneApp,
            })}
        >
            <FloatingCard isOneApp={isOneApp}>
                {!isOneApp && (
                    <>
                        <h3 className={css.title}>
                            {qualificationStage
                                ? t('program.title')
                                : t('program.generalQuestionTitle')}
                        </h3>
                        <p className={css.subtitle}>
                            {qualificationStage ? t('program.subtitle') : null}
                        </p>
                    </>
                )}
                {isOneApp && (
                    <>
                        <h3 className={css.oneAppTitle}>
                            {qualificationStage
                                ? t('program.oneAppTitle')
                                : t('program.oneAppGeneralQuestionTitle')}
                        </h3>
                        <p className={css.oneAppSubtitle}>
                            {qualificationStage ? subtitle : null}
                        </p>
                    </>
                )}

                {questionsToUse
                    .filter(question => {
                        const {id, necessity} = question;

                        return necessity === 'REQUIRED' || optionalQuestion(id);
                    })
                    .map(question => {
                        const {
                            id,
                            necessity,
                            answerType,
                            questionGroup,
                            possibleAnswers,
                        } = question;

                        return (
                            <UpsellQuestion
                                key={id}
                                id={id}
                                necessity={necessity}
                                answerType={answerType}
                                questionGroup={questionGroup}
                                possibleAnswers={possibleAnswers}
                                onChange={value => {
                                    onChange(id, value);
                                }}
                                errorMessage={errors[id]}
                            />
                        );
                    })}
                <Button
                    variant="primary"
                    className={cn(css.nextButton, {
                        [css.oneAppNextButton]: isOneApp,
                    })}
                    // disabled={!allowNext}
                    onClick={onSubmit}
                >
                    {t('buttons.next')}
                </Button>
            </FloatingCard>
        </div>
    );
};

Program.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default Program;
