/* eslint-disable no-use-before-define */
import qs from 'qs';

import MixpanelUtils from '@livongo/utils/mixpanel';

const PREFIX = 'v2.0 Upsell';
// Mixpanel Events
const AUTHENTICATION_LAND = `${PREFIX} - Authentication Land`;
const AUTHENTICATION_PASS = `${PREFIX} - Authentication Pass`;
const PROGRAM_SELECTION_LAND = `${PREFIX} - Program Selection Land`;
const PROGRAM_SELECTION_PASS = `${PREFIX} - Program Selection Pass`;
const QUESTIONS_LAND = `${PREFIX} - Questions Land`;
const QUESTIONS_PASS = `${PREFIX} - Questions Pass`;
const PROGRAM_PASS = `${PREFIX} - Program Pass`;
const PROGRAM_CONFIRMATION_LAND = `${PREFIX} - Program Confirmation Land`;
const PROGRAM_CONFIRMATION_PASS = `${PREFIX} - Program Confirmation Pass`;
const PROGRAMS_QUALIFIED = `${PREFIX} - Programs Qualified`;
const PROGRAMS_DISQUALIFIED = `${PREFIX} - Programs Disqualified`;
const SHIPPING_CONFIRMATION_LAND = `${PREFIX} - Shipping Confirmation Land`;
const SHIPPING_CONFIRMATION_PASS = `${PREFIX} - Shipping Confirmation Pass`;
const GENERAL_ERROR = `${PREFIX} - General Error`;
const MOBILE_HEART_BUTTON_CLICK = 'Mobile heart: Try to Register';
const MOBILE_HEART_REGISTERED = 'Mobile heart: User Registered';
const MOBILE_HEART_ERROR = 'Mobile heart: Register Error';
// Mixpanel Properties
const MULTIPLE_PROGRAMS = 'Multiple Programs';
const OFFERED_PROGRAMS = 'Offered Programs';
const SELECTED_PROGRAMS = 'Selected Programs';
const QUESTIONS = 'Questions';
const PROGRAMS = 'Programs';
const REASONS = 'Reasons';
const ERROR = 'Error';
const UTM_KEYWORDS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
];

export const authenticationLand = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: AUTHENTICATION_LAND,
        properties,
    });
};

export const authenticationPass = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: AUTHENTICATION_PASS,
        properties,
    });
};

export const programSelectionLand = (multiplePrograms, offeredPrograms) => {
    const properties = getBaseProperties();

    properties[MULTIPLE_PROGRAMS] = multiplePrograms;
    properties[OFFERED_PROGRAMS] = offeredPrograms;

    MixpanelUtils.track({
        event: PROGRAM_SELECTION_LAND,
        properties,
    });
};

export const programSelectionPass = (
    multiplePrograms,
    offeredPrograms,
    selectedPrograms
) => {
    const properties = getBaseProperties();

    properties[MULTIPLE_PROGRAMS] = multiplePrograms;
    properties[OFFERED_PROGRAMS] = offeredPrograms;
    properties[SELECTED_PROGRAMS] = selectedPrograms;

    MixpanelUtils.track({
        event: PROGRAM_SELECTION_PASS,
        properties,
    });
};

export const questionsLand = questions => {
    const properties = getBaseProperties();

    properties[QUESTIONS] = questions;

    MixpanelUtils.track({
        event: QUESTIONS_LAND,
        properties,
    });
};

export const questionsPass = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: QUESTIONS_PASS,
        properties,
    });
};

export const programPass = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: PROGRAM_PASS,
        properties,
    });
};

export const programConfirmationLand = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: PROGRAM_CONFIRMATION_LAND,
        properties,
    });
};

export const programsConfirmationPass = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: PROGRAM_CONFIRMATION_PASS,
        properties,
    });
};

export const programsQualified = programs => {
    const properties = getBaseProperties();

    properties[PROGRAMS] = programs;

    MixpanelUtils.track({
        event: PROGRAMS_QUALIFIED,
        properties,
    });
};

export const programsDisqualified = (programs, reasons) => {
    const properties = getBaseProperties();

    properties[PROGRAMS] = programs;
    properties[REASONS] = reasons;

    MixpanelUtils.track({
        event: PROGRAMS_DISQUALIFIED,
        properties,
    });
};

export const shippingConfirmationLand = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: SHIPPING_CONFIRMATION_LAND,
        properties,
    });
};

export const shippingConfirmationPass = redirectTo => {
    const properties = getBaseProperties();

    properties.redirectTo = redirectTo;

    MixpanelUtils.track({
        event: SHIPPING_CONFIRMATION_PASS,
        properties,
    });
};

export const mixpanelGeneralError = ({name, location, additionalInfo}) => {
    const properties = getBaseProperties();

    properties.name = name;
    properties.location = location;
    properties.additionalInfo = additionalInfo;

    MixpanelUtils.track({
        event: GENERAL_ERROR,
        properties,
    });
};

export const mobileHeartSubmitClick = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: MOBILE_HEART_BUTTON_CLICK,
        properties,
    });
};

export const mobileHeartRegistered = () => {
    const properties = getBaseProperties();

    MixpanelUtils.track({
        event: MOBILE_HEART_REGISTERED,
        properties,
    });
};

export const mobileHeartError = error => {
    const properties = getBaseProperties();

    properties[ERROR] = error;

    MixpanelUtils.track({
        event: MOBILE_HEART_ERROR,
        properties,
    });
};

const getBaseProperties = () => {
    const properties = {};
    let searchParamObj;

    if (typeof window !== 'undefined') {
        properties.Location = PREFIX;

        searchParamObj = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        UTM_KEYWORDS.forEach(kw => {
            const value = searchParamObj[kw];

            if (value?.length) {
                properties[`${kw} [current]`] = value;
            }
        });
    }

    return properties;
};

export const setSuperAndPeopleProperties = userId => {
    const properties = {};

    if (userId) properties['User ID'] = userId;

    const firstTouchProperties = {};
    let searchParamObj;

    if (typeof window !== 'undefined') {
        searchParamObj = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        const trackingID = searchParamObj?.tracking_id;

        if (trackingID) {
            properties['Livongo Tracking ID'] = trackingID;
        }

        UTM_KEYWORDS.forEach(kw => {
            const value = searchParamObj[kw];

            if (value?.length) {
                firstTouchProperties[`${kw} [first touch]`] = value;
                properties[`${kw} [last touch]`] = value;
            }
        });

        MixpanelUtils.identify();
        MixpanelUtils.setPeoplePropsOnce(firstTouchProperties);
        MixpanelUtils.setPeopleProps(properties);
        MixpanelUtils.setSuperPropsOnce(firstTouchProperties);
        MixpanelUtils.setSuperProps(properties);
    }
};
