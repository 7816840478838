import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import css from './modalStyle.scss';

const LabelMessage = ({text}) => {
    const {t} = useTranslation('messages', {useSuspense: false});

    return (
        <div className={css.modalContainer}>
            <h1>{t(text)}</h1>
        </div>
    );
};

LabelMessage.propTypes = {
    text: PropTypes.string.isRequired,
};

export default LabelMessage;
