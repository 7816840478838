import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@teladoc/pulse/ui/Button';
import Flyout from '@teladoc/pulse/ui/Flyout';
import IconCloseDefault from '@teladoc/pulse/icons/close-default.svg';

import {messageTypes} from 'constants/messages';
import {clearMessage} from 'store/message/message-actions';

import Timeout from './subComponents/Timeout';
import GeneralMessage from './subComponents/GeneralMessage';
import LabelMessage from './subComponents/LabelMessage';
import MemberSupportNumber from './subComponents/MemberSupportNumber';

import css from './Message.scss';

const {GENERAL, LABEL, MEMBER_SUPPORT, TIMEOUT} = messageTypes;

const Message = ({appearFrom}) => {
    const dispatch = useDispatch();
    const {message} = useSelector(state => state);
    const {type, id, text} = message;
    // TODO: Based on type, polish UI.

    const onClearMessage = useCallback(() => {
        dispatch(clearMessage());
        if (type === 'timeout') {
            window.open(process.env.REG_FLOW_URL, '_self');
        }
    }, [dispatch, type]);

    const subComponent = () => {
        switch (type) {
            case GENERAL:
                return <GeneralMessage id={id} />;
            case LABEL:
                return <LabelMessage text={text} />;
            case MEMBER_SUPPORT:
                return <MemberSupportNumber id={id} />;
            case TIMEOUT:
                return <Timeout id={id} />;
            default:
                return <GeneralMessage id={id} />;
        }
    };

    return (
        <div aria-live="assertive">
            {(id || text) && (
                <Flyout
                    className={css.modal}
                    showingClassName={css.showing}
                    containerClassName={css.container}
                    appearFrom={appearFrom}
                    renderClose={onCloseClick => {
                        return (
                            <Button
                                className={css.close}
                                onClick={onCloseClick}
                            >
                                <IconCloseDefault />
                            </Button>
                        );
                    }}
                    onHidden={onClearMessage}
                >
                    {subComponent()}
                </Flyout>
            )}
        </div>
    );
};

Message.propTypes = {
    appearFrom: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Message.defaultProps = {
    appearFrom: 'right',
};

export default Message;
