import {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import TextInput from '@teladoc/pulse/ui/TextInput';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Radio from '@teladoc/pulse/ui/Radio';
import Checkbox from '@teladoc/pulse/ui/Checkbox';
import Select from '@teladoc/pulse/ui/Select';
import Label from '@teladoc/pulse/ui/Label';
import PhoneInput from '@teladoc/pulse/ui/PhoneInput';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import ZipInput from '@teladoc/pulse/ui/ZipInput';
import StateSelect from '@teladoc/pulse/ui/StateSelect';
import {questionConfig, questionFieldTypes} from 'constants/questions';
import {questionUiType, IDK} from 'constants/type';
import A1cDate from './subComponents/A1cDate';
import A1cValue from './subComponents/A1cValue';
import BirthDate from './subComponents/BirthDate';
import Password from './subComponents/Password';
import MultiSelect from './subComponents/MultiSelect';
import Sms from './subComponents/Sms';
import TermsAndConditions from './subComponents/TermsAndConditions';
import Height from './subComponents/Height';
import Weight from './subComponents/Weight';
import {getIsOneApp} from 'utilities/utils';
import {renderLabel, getValidationErrorMessage} from './questions-utils';
import css from './Question.scss';

const {
    TEXT_INPUT,
    RADIO,
    CHECKBOX,
    SELECT,
    MULTI_SELECT,
    COMPOUND,
    PASSWORD,
    ADDRESS,
    A1C_DATE,
    A1C_VALUE,
    BIRTH_DATE,
    SMS_OPT_IN,
    TERMS_AND_CONDITIONS,
    REG_CODE,
    PROGRAM,
    HEIGHT,
    WEIGHT,
    PHONE_INPUT,
    ZIP,
    STATE,
} = questionUiType;

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
} = questionFieldTypes;

const BaseQuestion = ({
    questionName,
    name,
    required,
    uiType,
    dataType,
    options,
    onChange,
    defaultValue,
    errorMessage,
}) => {
    const {
        t,
        // i18n
    } = useTranslation('questions', {useSuspense: false});
    const isOneApp = getIsOneApp();
    const [userInput, setUserInput] = useState(null);
    const {phoneRegions, addressTypes} = useSelector(
        state => state.user.details
    );
    const disabledCountryPhoneRegion = phoneRegions
        ? phoneRegions.indexOf('INTERNATIONAL') === -1
        : true;

    const errorMessageParams = {
        brandName: isOneApp ? 'Teladoc Health' : 'Livongo',
    };

    const customValidationError = errorMessage ? (
        <FormElementError>
            {t(errorMessage, errorMessageParams)}
        </FormElementError>
    ) : null;

    // Get question UI configuration.
    const {
        // uiType: defaultUiType, // may be undefined
        type,
        pattern,
        autocomplete,
        // options: defaultOptions,
        title,
        label: questionLabel,
        labels,
        ariaLabel,
        placeholder,
        mask,
        oneAppLabel,
        oneAppTitle,
        // translateOptions = true,
    } = questionConfig[questionName] || {};

    // Get the correct translation key for the matching question name and user Input
    const validationErrorMessage = t(
        getValidationErrorMessage(questionName, userInput)
    );

    const handleChange = newValue => {
        if (onChange) {
            onChange(newValue);
        }
        setUserInput(newValue);
    };

    return (
        <div className={css.root}>
            {title && (
                <h1
                    className={cn(css.title, {
                        [css.oneAppTitle]: isOneApp,
                    })}
                >
                    {isOneApp && oneAppTitle ? t(oneAppTitle) : t(title)}
                </h1>
            )}
            {labels &&
                Array.isArray(labels) &&
                labels.map((label, i) => (
                    <div
                        key={i}
                        className={cn(css.subtitle, {
                            [css.oneAppSubtitle]: isOneApp,
                        })}
                    >
                        {t(label)}
                    </div>
                ))}
            {/* <button onClick={methodDoesNotExist}>Break the world</button>; */}
            {/* Error statement to trigger the Sentry log*/}
            {(() => {
                switch (uiType) {
                    case TEXT_INPUT:
                        return (
                            <TextInput
                                {...renderLabel(t(questionLabel), t(ariaLabel))}
                                id={questionName}
                                name={name}
                                type={type}
                                defaultValue={defaultValue}
                                placeholder={t(placeholder)}
                                pattern={pattern}
                                autoComplete={autocomplete}
                                mask={mask}
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                                required={required}
                            />
                        );
                    case RADIO:
                        return (
                            <RadioGroup
                                id={questionName}
                                name={name}
                                label={
                                    <Label
                                        className={cn(css.questionLabel, {
                                            [css.displayNoneLabel]:
                                                isOneApp && !oneAppLabel,
                                            [css.oneAppquestionLabel]: isOneApp,
                                        })}
                                        i18nRequiredVisualLabel=" "
                                    >
                                        {isOneApp && oneAppLabel
                                            ? t(oneAppLabel)
                                            : t(questionLabel)}
                                    </Label>
                                }
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                required={required}
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    questionName === 'INSULIN_USAGE'
                                        ? handleChange(
                                              parseInt(evt.target.value, 10)
                                          )
                                        : handleChange(evt.target.value);
                                }}
                            >
                                {options.map(({value, label}) => {
                                    return (
                                        <Radio
                                            key={`${questionName}-${value}`}
                                            id={`${questionName}-item-${value}`}
                                            value={value}
                                            label={
                                                <Label
                                                    className={cn({
                                                        [css.oneApplabel]: isOneApp,
                                                    })}
                                                    i18nRequiredVisualLabel=" "
                                                >
                                                    {label}
                                                </Label>
                                            }
                                            title={label}
                                            defaultChecked={
                                                value === defaultValue
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        );
                    case CHECKBOX:
                        return (
                            <Checkbox
                                id={questionName}
                                name={name}
                                value="true"
                                label={
                                    <Label i18nRequiredVisualLabel=" ">
                                        {t(questionLabel)}
                                    </Label>
                                }
                                defaultChecked={defaultValue === 'true'}
                                i18nItemLabel={t(title)}
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.checked);
                                }}
                                required={required}
                            />
                        );
                    case SELECT:
                        return (
                            <Select
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                                label={
                                    <Label i18nRequiredVisualLabel=" ">
                                        {t(questionLabel)}
                                    </Label>
                                }
                                placeholder={t(placeholder)}
                                i18nItemLabel={t(ariaLabel)}
                                items={options}
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.value);
                                }}
                                required={required}
                            />
                        );
                    case MULTI_SELECT:
                        return (
                            <MultiSelect
                                id={questionName}
                                name={name}
                                required={required}
                                items={options}
                                defaultValues={defaultValue}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case PASSWORD:
                        return (
                            <Password
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={defaultValue}
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.target.value);
                                }}
                            />
                        );
                    case A1C_DATE:
                        return (
                            <A1cDate
                                id={questionName}
                                name={name}
                                required={required}
                                defaultYear={defaultValue?.year}
                                defaultMonth={defaultValue?.month}
                                defaultDisabled={defaultValue === IDK}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case A1C_VALUE:
                        return (
                            <A1cValue
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={
                                    defaultValue !== IDK ? defaultValue : null
                                }
                                defaultDisabled={defaultValue === IDK}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case BIRTH_DATE:
                        return (
                            <BirthDate
                                id={questionName}
                                name={name}
                                required={required}
                                defaultYear={defaultValue?.year}
                                defaultMonth={defaultValue?.month}
                                defaultDay={defaultValue?.day}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case TERMS_AND_CONDITIONS:
                        return (
                            <TermsAndConditions
                                id={questionName}
                                name={name}
                                required={required}
                            />
                        );
                    case SMS_OPT_IN: {
                        return (
                            <Sms
                                id={questionName}
                                name={name}
                                required={required}
                                defaultValue={
                                    defaultValue !== IDK ? defaultValue : null
                                }
                                customValidationError={customValidationError}
                            />
                        );
                    }
                    case HEIGHT:
                        return (
                            <Height
                                id={questionName}
                                name={name}
                                required={required}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case WEIGHT:
                        return (
                            <Weight
                                id={questionName}
                                name={name}
                                required={required}
                                i18nItemLabel={t(ariaLabel)}
                                defaultValue={
                                    defaultValue !== IDK ? defaultValue : null
                                }
                                defaultDisabled={defaultValue === IDK}
                                customValidationError={customValidationError}
                                onChange={handleChange}
                            />
                        );
                    case PHONE_INPUT:
                        return (
                            <PhoneInput
                                id={questionName}
                                customValidationError={customValidationError}
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                defaultCountry="US"
                                disableCountrySelect={
                                    disabledCountryPhoneRegion
                                }
                                autoComplete="tel-national"
                                i18nItemLabel={t(ariaLabel)}
                                onChange={val => {
                                    handleChange(val);
                                }}
                                required={required}
                                name={name}
                            />
                        );
                    case ZIP:
                        return (
                            <ZipInput
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                id={questionName}
                                name={name}
                                i18nItemLabel={ariaLabel}
                                placeholder={t(placeholder)}
                                pattern={pattern}
                                onChange={evt => {
                                    const value =
                                        evt.target.value.length === 6
                                            ? evt.target.value.slice(0, 5)
                                            : evt.target.value;

                                    handleChange(value);
                                }}
                                required={required}
                            />
                        );
                    case STATE:
                        return (
                            <StateSelect
                                id={questionName}
                                name={name}
                                defaultValue={defaultValue}
                                label={
                                    <Label i18nRequiredVisualLabel=" ">
                                        {t(questionLabel)}
                                    </Label>
                                }
                                placeholder={t(placeholder)}
                                i18nItemLabel={t(ariaLabel)}
                                error={
                                    <FormElementError>
                                        {validationErrorMessage}
                                    </FormElementError>
                                }
                                customValidationError={customValidationError}
                                onChange={evt => {
                                    handleChange(evt.value);
                                }}
                                includeEmpty={false}
                                includeMilitary={addressTypes.includes(
                                    'MILITARY'
                                )}
                                includeUSTerritories={addressTypes.includes(
                                    'US_TERRITORY'
                                )}
                                required={required}
                            />
                        );
                    default:
                        // TODO: Display proper message.
                        return null;
                }
            })()}
        </div>
    );
};

BaseQuestion.propTypes = {
    questionName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    dataType: PropTypes.oneOf([
        TEXT_FIELD,
        SINGLE_SELECT_ENUM,
        MULTI_SELECT_ENUM,
        BOOLEAN_FIELD,
        INTEGER_FIELD,
        FLOAT_FIELD,
        DATE_FIELD,
        COMPOUND_FIELD,
    ]),
    uiType: PropTypes.oneOf([
        TEXT_INPUT,
        RADIO,
        CHECKBOX,
        SELECT,
        MULTI_SELECT,
        COMPOUND,
        PASSWORD,
        ADDRESS,
        A1C_DATE,
        A1C_VALUE,
        BIRTH_DATE,
        SMS_OPT_IN,
        TERMS_AND_CONDITIONS,
        REG_CODE,
        PROGRAM,
        HEIGHT,
        WEIGHT,
        PHONE_INPUT,
        ZIP,
        STATE,
    ]).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    errorMessage: PropTypes.string,
};

BaseQuestion.defaultProps = {
    required: false,
};

export default BaseQuestion;
