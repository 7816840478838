import {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {questionConfig} from 'constants/questions';
import {IDK} from 'constants/type';
import useAllMonths from 'hook/useAllMonths';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';

import Checkbox from '@teladoc/pulse/ui/Checkbox';
import FormElementError from '@teladoc/pulse/ui/FormElementError';
import Label from '@teladoc/pulse/ui/Label';
import Select from '@teladoc/pulse/ui/Select';
import TextInput from '@teladoc/pulse/ui/TextInput';

import css from './A1cDate.scss';

const CURRENT_YEAR = String(new Date().getFullYear());

const A1cDate = ({
    id,
    name,
    required,
    defaultYear,
    defaultMonth,
    defaultDisabled = false,
    customValidationError,
    onChange,
}) => {
    const {t} = useTranslation('questions');
    const [allMonths, currentYearMonths] = useAllMonths();
    const {birthDate} = useSelector(state => state.user);
    const dobYear = getYear(new Date(birthDate));
    const dobMonth = getMonth(new Date(birthDate));
    const currentYearBirthMonths = allMonths.filter(
        (month, i) => i >= dobMonth
    );
    const [selectMonthKey, setSelectMonthKey] = useState(new Date().getTime());
    const currentYear = new Date().getUTCFullYear();
    let yearRange = [];
    let year = dobYear >= 2000 ? dobYear : 2000;

    for (year; year <= currentYear; year++) {
        yearRange.push({
            label: String(year),
            value: String(year),
        });
    }

    yearRange = yearRange.reverse();
    // Get question UI configuration.
    const {
        error,
        checkboxLabel,
        yearAriaLabel,
        monthAriaLabel,
        yearPlaceholder,
        monthPlaceholder,
        errorYearEmpty,
        errorMonthEmpty,
    } = questionConfig[id] || {};

    const [a1cYear, setA1cYear] = useState(
        defaultYear ? `${defaultYear}` : null
    );
    const [a1cMonth, setA1cMonth] = useState(
        defaultMonth ? `${defaultMonth}` : null
    );
    const [date, setDate] = useState(
        defaultDisabled ? IDK : `${defaultYear}-${defaultMonth}-01`
    );
    const [disableA1CInput, setDisableA1CInput] = useState(defaultDisabled);

    const onA1CYearChange = ({value}) => {
        setA1cYear(value);
        setSelectMonthKey(new Date().getTime());
        setA1cMonth(null);
        onChange(null);
    };

    const onA1CMonthChange = ({value}) => {
        setA1cMonth(value);

        if (a1cYear) {
            let val = value;

            if (value < 9) {
                // eslint-disable-next-line no-unused-vars
                val = `0${value}`;
            }
            const dateValue = `${a1cYear}-${val}-01`;

            setDate(dateValue);
            if (onChange) {
                onChange(dateValue);
            }
        }
    };

    const onA1cChange = evt => {
        setDisableA1CInput(evt.target.checked);
        if (!evt.target.checked && a1cYear && a1cMonth) {
            const dateValue = `${a1cYear}-${a1cMonth}-01`;

            setDate(dateValue);
            if (onChange) {
                onChange(dateValue);
            }
        } else if (!evt.target.checked) {
            if (onChange) {
                onChange('');
            }
        } else {
            if (onChange) {
                onChange('IDK');
            }
        }
    };

    const errorValidation = errorMessage => {
        return !disableA1CInput && customValidationError ? (
            <FormElementError>{t(errorMessage)}</FormElementError>
        ) : null;
    };

    const getMonths = () => {
        if (a1cYear === CURRENT_YEAR) {
            return currentYearMonths;
        } else if (a1cYear === String(dobYear)) {
            return currentYearBirthMonths;
        } else {
            return allMonths;
        }
    };

    return (
        <div className={css.root}>
            <div className={css.date}>
                <Select
                    id={`year_${id}`}
                    defaultValue={a1cYear}
                    classNameRoot={cn(css.selectYear)}
                    i18nItemLabel={yearAriaLabel}
                    items={yearRange}
                    placeholder={t(yearPlaceholder)}
                    error={<FormElementError>{t(error)}</FormElementError>}
                    disabled={disableA1CInput}
                    customValidationError={
                        !a1cYear && !disableA1CInput
                            ? errorValidation(errorYearEmpty)
                            : null
                    }
                    onChange={onA1CYearChange}
                    required={required || !disableA1CInput}
                />
                <Select
                    key={selectMonthKey}
                    id={`month_${id}`}
                    defaultValue={a1cMonth}
                    classNameRoot={cn(css.selectMonth)}
                    i18nItemLabel={monthAriaLabel}
                    items={getMonths()}
                    placeholder={t(monthPlaceholder)}
                    error={<FormElementError>{t(error)}</FormElementError>}
                    disabled={disableA1CInput}
                    onChange={onA1CMonthChange}
                    customValidationError={
                        !a1cMonth && !disableA1CInput
                            ? errorValidation(errorMonthEmpty)
                            : null
                    }
                    required={required || !disableA1CInput}
                />
            </div>
            <Checkbox
                id={`chk_${id}`}
                i18nItemLabel={t(checkboxLabel)}
                label={<Label>{t(checkboxLabel)}</Label>}
                error={<FormElementError>{t(error)}</FormElementError>}
                onChange={onA1cChange}
                defaultChecked={defaultDisabled}
            />
            <TextInput
                id={id}
                name={name}
                type="hidden"
                // TODO: Update to a more proper label.
                i18nItemLabel="hidden"
                value={date}
            />
        </div>
    );
};

A1cDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number,
    defaultMonth: PropTypes.number,
    defaultDisabled: PropTypes.bool,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} dateText - The date in string format YYYY-MM-1;
     */
    onChange: PropTypes.func,
};

export default A1cDate;
