import PropTypes from 'prop-types';
import cn from 'classnames';
import css from './FloatingCard.scss';

const FloatingCard = ({isOneApp, children}) => {
    return (
        <div
            className={cn(css.root, {
                [css.oneApproot]: isOneApp,
            })}
        >
            <div
                className={cn(css.container, {
                    [css.oneAppContainer]: isOneApp,
                })}
            >
                {children}
            </div>
        </div>
    );
};

FloatingCard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    isOneApp: PropTypes.bool,
};

export default FloatingCard;
