import {useState, useEffect} from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Select from '@teladoc/pulse/ui/Select';
import FormElementError from '@teladoc/pulse/ui/FormElementError';

import {questionConfig} from 'constants/questions';

import css from './Height.scss';

const Height = ({id, required, customValidationError, onChange}) => {
    const {t} = useTranslation('questions', {useSuspense: false});
    const [feet, setFeet] = useState(null);
    const [inches, setInches] = useState(null);
    const {
        selectFeetAriaLabel,
        selectInchesAriaLabel,
        feetPlaceholder,
        inchesPlaceholder,
        error,
        errorHeightFeet,
        errorHeightInches,
    } = questionConfig[id] || {};

    const errorValidation = errorMessage => {
        return customValidationError ? (
            <FormElementError>{t(errorMessage)}</FormElementError>
        ) : null;
    };

    const selectFeetValue = range(3, 9).map(feetValue => {
        return {
            label: String(`${feetValue}'`),
            value: String(feetValue),
        };
    });
    const selectInchesValue = range(12).map(inchesValue => {
        return {
            label: String(`${inchesValue}"`),
            value: String(inchesValue),
        };
    });
    const onSelectFeetChange = evt => {
        if (!evt) {
            return;
        }

        const {value} = evt;

        if (value !== feet) {
            setFeet(value);
        }
    };
    const onSelectInchesChange = evt => {
        if (!evt) {
            return;
        }

        const {value} = evt;

        if (value !== inches) {
            setInches(value);
        }
    };

    useEffect(() => {
        if (onChange && feet && inches) {
            onChange(12 * parseInt(feet, 10) + parseInt(inches, 10));
        }
    }, [feet, inches]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.root}>
            <Select
                id="select_feet"
                classNameRoot={css.selectHeight}
                onChange={onSelectFeetChange}
                i18nItemLabel={t(selectFeetAriaLabel)}
                items={selectFeetValue}
                placeholder={t(feetPlaceholder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={
                    !feet ? errorValidation(errorHeightFeet) : null
                }
                required={required}
            />
            <Select
                id="select_inches"
                classNameRoot={css.selectHeight}
                i18nItemLabel={t(selectInchesAriaLabel)}
                items={selectInchesValue}
                placeholder={t(inchesPlaceholder)}
                error={<FormElementError>{t(error)}</FormElementError>}
                customValidationError={
                    !inches ? errorValidation(errorHeightInches) : null
                }
                required={required}
                onChange={onSelectInchesChange}
            />
        </div>
    );
};

Height.propTypes = {
    id: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    /** A custom validation error (typically a FormElementError component) to display and override any other possible error passed when this component is part of a form and is invalid. */
    customValidationError: PropTypes.element,
    /**
     * Function to execute when the input changes.
     *
     * @param {String} a1cValue - Either the A1c Value or the IDK string.
     */
    onChange: PropTypes.func,
};

export default Height;
