// The redux store is hooked up to Sentry to send data about the user's state to help us solve production issues missed during dev/QA.
// The below array is the user data that is excluded from that state, otherwise known as PII.
// We should not be sending ANY PII to Sentry or any third party tool.
export const PII_STATE_EXCLUSIONS = [
    'address',
    'birthDate',
    'email',
    'firstName',
    'lastName',
];
