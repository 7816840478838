import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import {messageValues} from 'constants/messages';
import {clearMessage} from 'store/message/message-actions';
import css from './modalStyle.scss';

const GeneralMessage = ({id}) => {
    const {t} = useTranslation('messages', {useSuspense: false});
    const dispatch = useDispatch();
    const messageValue = messageValues[id] || {};

    const {
        title,
        content,
        primaryButtonText,
        primaryButtonLink,
        secondaryButtonText,
        secondaryButtonLink,
        cancelText,
    } = messageValue;

    const onPrimaryClick = evt => {
        if (primaryButtonLink[0] === '/') {
            window.open(primaryButtonLink, '_self');
        } else {
            window.open(primaryButtonLink, '_blank', 'noopener noreferrer');
        }
    };
    const onSecondaryClick = evt => {
        if (secondaryButtonLink[0] === '/') {
            window.open(secondaryButtonLink, '_self');
        } else {
            window.open(secondaryButtonLink, '_blank', 'noopener noreferrer');
        }
    };

    const onCancelClick = evt => {
        dispatch(clearMessage());
    };

    return (
        <div className={css.modalContainer}>
            {title && <h1>{t(title)}</h1>}

            <div className={css.contentContainer}>
                {content && Array.isArray(content) ? (
                    content.map((contentLine, i) => (
                        <p key={i}>{t(contentLine)}</p>
                    ))
                ) : (
                    <p>{t(content)}</p>
                )}
            </div>

            {primaryButtonText && (
                <Button
                    className={css.modalButton}
                    color="primary"
                    onClick={onPrimaryClick}
                >
                    {t(primaryButtonText)}
                </Button>
            )}
            {secondaryButtonText && (
                <Button className={css.modalButton} onClick={onSecondaryClick}>
                    {t(secondaryButtonText)}
                </Button>
            )}
            {cancelText && (
                <Button className={css.modalButton} onClick={onCancelClick}>
                    {t(cancelText)}
                </Button>
            )}
        </div>
    );
};

GeneralMessage.propTypes = {
    id: PropTypes.string.isRequired,
};

export default GeneralMessage;
