import Config from 'config';

const messageIds = {
    MISSING_AUTH_TOKEN: 'MISSING_AUTH_TOKEN',
    MISSING_PROGRAM_PARAM: 'MISSING_PROGRAM_PARAM',
    EXPIRED_INVALID_TOKEN: 'EXPIRED_INVALID_TOKEN',
    ERROR_BAD_REQUEST: 'ERROR_BAD_REQUEST',
    ERROR_QUESTIONS_FETCH: 'ERROR_QUESTIONS_FETCH',
    ERROR_CREATE_ACCOUNT: 'ERROR_CREATE_ACCOUNT',
    ERROR_MOBILE_HEART: 'ERROR_MOBILE_HEART',
    MODAL_ACCOUNT_ALREADY_EXISTS: 'MODAL_ACCOUNT_ALREADY_EXISTS',
    MODAL_CLIENT_CAPPED: 'MODAL_CLIENT_CAPPED',
    MODAL_REGISTRATION_CLOSED: 'MODAL_REGISTRATION_CLOSED',
    MODAL_TIMEOUT: 'MODAL_TIMEOUT',
    MODAL_UNDER_AGE: 'MODAL_UNDER_AGE',
};

export const messageTypes = {
    GENERAL: 'GENERAL', // General, for most use cases, allows for title, content, primary button, and secondary button
    LABEL: 'LABEL',
    MEMBER_SUPPORT: 'MEMBER_SUPPORT',
    TIMEOUT: 'TIMEOUT',
};

const {registerStartUrl, healthyEating} = Config;

// translations located in i18n/locales/{country code}/messages
export const messageValues = {
    [messageIds.MISSING_AUTH_TOKEN]: {
        title: 'MISSING_AUTH_TOKEN.title',
        content: 'MISSING_AUTH_TOKEN.content',
    },
    [messageIds.MISSING_PROGRAM_PARAM]: {
        title: 'MISSING_PROGRAM_PARAM.title',
        content: 'MISSING_PROGRAM_PARAM.content',
    },
    [messageIds.EXPIRED_INVALID_TOKEN]: {
        title: 'EXPIRED_INVALID_TOKEN.title',
        content: 'EXPIRED_INVALID_TOKEN.content',
    },
    [messageIds.ERROR_BAD_REQUEST]: {
        title: 'ERROR_BAD_REQUEST.title',
        content: 'ERROR_BAD_REQUEST.content',
    },
    [messageIds.ERROR_QUESTIONS_FETCH]: {
        title: 'ERROR_QUESTIONS_FETCH.title',
    },
    [messageIds.ERROR_CREATE_ACCOUNT]: {
        title: 'ERROR_CREATE_ACCOUNT.title',
        content: 'ERROR_CREATE_ACCOUNT.content',
    },
    [messageIds.ERROR_MOBILE_HEART]: {
        title: 'ERROR_MOBILE_HEART.title',
        content: 'ERROR_MOBILE_HEART.content',
        content1: 'ERROR_MOBILE_HEART.content1',
    },
    [messageIds.MODAL_TIMEOUT]: {
        title: 'MODAL_TIMEOUT.title',
        content: 'MODAL_TIMEOUT.content',
        primaryButtonText: 'MODAL_TIMEOUT.buttonLabel',
        primaryButtonLink: registerStartUrl,
    },
    [messageIds.MODAL_ACCOUNT_ALREADY_EXISTS]: {
        title: 'MODAL_ACCOUNT_ALREADY_EXISTS.title',
        content: 'MODAL_ACCOUNT_ALREADY_EXISTS.content',
        primaryButtonText: 'MODAL_ACCOUNT_ALREADY_EXISTS.login',
        cancelText: 'MODAL_ACCOUNT_ALREADY_EXISTS.buttonLabel',
    },
    [messageIds.MODAL_CLIENT_CAPPED]: {
        title: 'MODAL_CLIENT_CAPPED.title',
        content: [
            'MODAL_CLIENT_CAPPED.content1',
            'MODAL_CLIENT_CAPPED.content2',
            'MODAL_CLIENT_CAPPED.content3',
        ],
        primaryButtonText: 'MODAL_CLIENT_CAPPED.buttonLabel',
        primaryButtonLink: healthyEating,
    },
    [messageIds.MODAL_REGISTRATION_CLOSED]: {
        title: 'MODAL_REGISTRATION_CLOSED.title',
        content: [
            'MODAL_REGISTRATION_CLOSED.content1',
            'MODAL_REGISTRATION_CLOSED.content2',
            'MODAL_REGISTRATION_CLOSED.content3',
        ],
        primaryButtonText: 'MODAL_REGISTRATION_CLOSED.buttonLabel',
        primaryButtonLink: healthyEating,
    },
    [messageIds.MODAL_UNDER_AGE]: {
        title: 'MODAL_UNDER_AGE.title',
        content: ['MODAL_UNDER_AGE.content1', 'MODAL_UNDER_AGE.content2'],
        primaryButtonText: 'MODAL_UNDER_AGE.buttonLabel',
        primaryButtonLink: healthyEating,
    },
};

export default messageIds;
